import { CommonUtilities }  from '../../commonUtils';

export default class ClientProfileSevices {

    static AuditTrailListing() {
        var url = 'contract/emailActivity/list?event=open,bounce,deferred,delivered,dropped,processed&queueId=&email=&dateFrom=&dateTo=&pageNumber=1&pageSize=10';
        return CommonUtilities.getData(url);
    }

    static AuditTrailChronological(param:any) {
        var url = 'auditTrail/searchLog?'+ CommonUtilities.buildQueryString(param , true , true);;
        return CommonUtilities.getData(url);
    }

    static AuditTrailCount(param:any) {
        var url = 'auditTrail/searchLogCount?'+ CommonUtilities.buildQueryString(param , true , true);;
        return CommonUtilities.getData(url);
    }

        static CurrentJobs( Data:{}) {            
        //var url = 'contracts/jobStatus?include=1%7C2&pageSize=10&pageNumber=1';
        var url = 'contracts/jobStatus?include=1|2&' +Data;
        return CommonUtilities.getData(url);
    }

    static AsynchronousJobs(Data:{}) {
        var url = 'common/getSqlJob?' + Data;
        var url1 = 'common/getSqlJob?pageSize=10&pageNumber=1';
        return CommonUtilities.getData(url1);
    }


    static EmailStatementJobs(Data:{}) {
        var url = 'contracts/jobStatus?include=3&'+ Data;
        return CommonUtilities.getData(url);
    }

    static CurrentJobsExport(Data : any) {
        var url = 'contracts/jobStatus/export?include='+ Data;
       // return CommonUtilities.exportWithToken('GET', url, {}, '_blank', 'JobStatus');
       CommonUtilities.exportVsWindow(url);
    }

    static CurrentJobsClear(Data : any) {
        var url = 'contracts/jobStatus?include=' + Data.include + 'jobId='+ Data.jobId;
        return CommonUtilities.deleteData(url);
    }
    
    static EmailJobsClear(Data : any) {
        var url = 'contracts/jobStatus?include=' + Data.include + 'jobId='+ Data.jobId;
        return CommonUtilities.deleteData(url);
    }

    static EmailListing(data:any) {
        console.log(data)
        //var url = 'contract/emailActivity/list?event='+data.event+'&queueId=&email='+data.email +'&dateFrom='+data.dateFrom +'&dateTo=' +data.dateTo +'&pageNumber='+data.pageNumber +'&pageSize=' +data.pageSize ;
       var url = 'contract/emailActivity/list?'+ CommonUtilities.buildQueryString(data , true , true) ;
        return CommonUtilities.getData(url);
    }

    /*Email Dropdown filters */ 

    static EmailFilters(Data:{}) {
        var url = 'contract/emailActivity/list?event=open'+ Data;
        return CommonUtilities.getData(url);
    }

    static EmailExport(data:any) {
        var url = 'contract/emailActivity/list?'+  CommonUtilities.buildQueryString(data , true , true) ;
       // return CommonUtilities.getData(url);
       return CommonUtilities.exportWithToken('GET', url, {}, '_blank', "EmailFeed");
    }
}