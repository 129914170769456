import Dashboard from '../../components/Dashboard/dashboard';
import IndexDashboard from '../../components/Dashboard/indexDashboard';
import AuditTrail from '../User Profile/AuditTrail';
import ClientProfile from '../../components/User Profile/Client Profiles/ClientProfile';
import Email from '../User Profile/Email';
import CurrentJobs from '../User Profile/CurrentJobs';
import React, { useState, useEffect } from 'react';
import { CommonUtilities } from '../../commonUtils';
import dashboardService from '../../components/Dashboard/dashboardServices';
import Tooltip from 'rc-tooltip';
import ClientProfileServices from 'components/User Profile/Client Profiles/clientProfileServices';

export default function Navbar() {

    const [pageNumber, setPageNumber] = useState(0);

    var userAccessMenu = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string).userMenu;
    var liveAccessToken = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string).liveOption;

    const SubEntities = () => {
        return (
            <div className='hover-box pl pull-right ws-12'>



                {userAccessMenu.map(function (subEntity: any, indexSubEntities: number) {



                    if (subEntity.subEntityType) {
                        return (
                            CommonUtilities.portalSelection(subEntity)
                        );
                    } else {





                        return (
                            <div className='box mb ws-12'>
                                <div className='box-header'>
                                    {subEntity.entityName}

                                    {indexSubEntities == 0 && <Logout />}

                                </div>
                                <div className='box-content'>
                                    {subEntity.subEntities?.map((userMenu: any) => {

                                        //console.log(userMenu.subEntityRef);

                                        if (userMenu.subEntityRef == 201) {
                                            return <ClientProfile />
                                        } else if (userMenu.subEntityRef == 202) {
                                            return <CurrentJobs />
                                        } else if (userMenu.subEntityRef == 203) {
                                            return <Email />
                                        } else if (userMenu.subEntityRef == 204) {
                                            return <AuditTrail />
                                        } else if (userMenu.subEntityRef == 205) {
                                            return (
                                                <a href='apps/userManagement/#/register' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-contacts"></i>
                                                        <span className="m0 pts ">User MGT</span>
                                                    </div>
                                                </a>);

                                        } else if (userMenu.subEntityRef == 206) {
                                            return (
                                                <a href='apps/systemSettings/#/periodMGT' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-contacts"></i>
                                                        <span className="m0 pts ">Period MGT</span>
                                                    </div>
                                                </a>);

                                        } else if (userMenu.subEntityRef == 207) {
                                            return (
                                                <a href='apps/systemSettings/#/controlPanel' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-settings1"></i>
                                                        <span className="m0 pts ">Control Pannel</span>
                                                    </div>
                                                </a>);

                                        } else if (userMenu.subEntityRef == 208) {
                                            return (
                                                <a href='apps/systemListings/#/' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-stack2"></i>
                                                        <span className="m0 pts ">System Listing</span>
                                                    </div>
                                                </a>);
                                        } else if (userMenu.subEntityRef == 209) {
                                            return (
                                                <a href='../../' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-refresh"></i>
                                                        <span className="m0 pts ">Recycle Pool</span>
                                                    </div>
                                                </a>);
                                        } else if (userMenu.subEntityRef == 210) {
                                            return (
                                                <a href='../../' className="pull-left">
                                                    <div className="box-primary ms text-center iconLogo pull-left  mrl">
                                                        <i className="box icon-refresh1"></i>
                                                        <span className="m0 pts ">Refresh</span>
                                                    </div>
                                                </a>);
                                        }


                                        // return(
                                        //     <div className="box-primary text-center iconLogo pull-left" key={ index }>
                                        //     <a  onClick={() => setPageNumber(userMenu.subEntityRef)} >
                                        //         <div className="ws-12">
                                        //             <i className={'box icon-' + userMenu.subEntityCode} />
                                        //             <span className="m0 pts" >{userMenu.subEntity} </span>
                                        //         </div>
                                        //     </a>
                                        // </div>
                                        // )


                                    })}
                                </div>
                            </div>
                        );


                    }
                })}

                <div className="box-primary text-center iconLogo pull-right hide">
                    <a href="/apps/index.html">
                        <div className="ws-12">
                            <i className="box pm icon-user2"></i>
                            Profile
                        </div>
                    </a>
                </div>
            </div>

        )
    }

    const LiveAccess = () => {
        if (liveAccessToken) {
            return (
                <div className="box-primary text-center iconLogo pull-right">
                    <a href="/apps/index.html">
                        <div className="ws-12">
                            <i className="box pm icon-broadcast"></i>
                            Live
                        </div>
                    </a>
                </div>
            )
        } else {
            return (<></>);
        }
    }

    const Logout = () => {
        return (
            <button className='btn-purple pull-right btn-xxs' onClick={CommonUtilities.clearAuthToken}>
                <i className="icon-stand-by"></i>
                <span className='ml mr'>
                    Logout
                </span>
            </button>)
    }


    const changePage = (e: any) => {
        setPageNumber(e);
        //pageNumber = e ;
    }

    const handleRecyclePool = () => {
        dashboardService.recyclePool().then(res => {
            console.log('API response:', res)
        })
            .catch(error => {
                console.log('API CALL ERROR', error);
            });
    }


    const clickHandler = (toggle: any) => {
        console.log(toggle.target.value);
        document.body.classList.remove('Default');
        document.body.classList.remove('CherryRed');
        document.body.classList.remove('Beggars');

        document.body.classList.add(toggle.target.value);

    }

    return (
        <div className='pull-right' >

            <div className="hover-box pl pull-right" >

                <button className='btn-purple pull-right hide'>
                    <i className="icon-menu text-l width-auto pull-right mr btn-xs" onClick={CommonUtilities.clearAuthToken}></i>
                </button>

                <div className="box-primary text-center iconLogo pull-right">
                    <a href="/apps/index.html">
                        <div className="ws-12">
                            <i className="box pm icon-menu"></i>
                            Menu
                        </div>
                    </a>
                </div>

                <ul className="hover-block rightReset landing ws-6 pull-left mr" ><Dashboard /></ul>

            </div>



            <div className="hover-box pl pull-right" >
                { }
                <div className="box-primary text-center iconLogo pull-right">
                    <a href="/apps/index.html">
                        <div className="ws-12">
                            <i className="box pm icon-user2"></i>
                            Profile
                        </div>
                    </a>
                </div>

                <ul className="hover-block rightReset landing ws-6 pull-left mr">
                    <SubEntities></SubEntities>
                </ul>
            </div>

            <LiveAccess></LiveAccess>

            <Tooltip placement="bottom" overlayStyle={{ width: '590px', height: '325px' }} trigger={['hover']} overlay={
                <div className='box'>
                    <div className='box-header'>
                        Theme Customization
                    </div>
                    <div className='box-content'>

                        <div className='pm'>
                            <input type='radio' name="theme" value="Default" className='mt' onClick={(e) => clickHandler(e)} /> Default
                        </div>

                        <div className='pm'>
                            <input type='radio' name="theme" value="CherryRed" className='mt' onClick={(e) => clickHandler(e)} /> Red Berry
                        </div>

                        <div className='pm'>
                            <input type='radio' name="theme" value="Beggars" className='mt' onClick={(e) => clickHandler(e)} /> Grey
                        </div>

                    </div>
                </div>}>

                <div className="box-primary text-center iconLogo pull-right">
                    <a href="/apps/index.html">
                        <div className="ws-12">
                            <i className="box pm icon-magic-wand"></i>
                            Theme
                        </div>
                    </a>
                </div>
            </Tooltip>


        </div>
    )
}