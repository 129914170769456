import Login from './components/User/Login';
import IndexDashboard from './components/Dashboard/indexDashboard';
import MobileApp from "./components/MobileApp/MobileApp";
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import Monitoring from './components/Common/monitor';
import DirectoriesIndex from './components/directories/directoriesIndex';
import React, { Suspense, useState, useEffect } from "react";

declare global { var period : any ; var selectedContact : any ; var portalLogo : any ;
  var periodCount:any ; var currectPeriod:any ;var lookUpTypeList:any ; var tagColor:any; var systemFormatsList:any; var containers: any;

}


function App() {
  if(window.sessionStorage.getItem('loggedUserRight')){

    return (

  
      <BrowserRouter basename='/'>
        
        <Routes>
          <Route path='/' element={<IndexDashboard />} />
          <Route path='/apps/directories/' element={<DirectoriesIndex />} />
          <Route path='/mobileApp' element={<MobileApp />} />
          <Route path='/login' element={<IndexDashboard />} />
          <Route path='/monitoring' element={<Monitoring />} />
        </Routes>
      </BrowserRouter>
      
    );
  }else{
    return (<Login/>); 
  }
}

export default App;