import React, { useState, useEffect } from "react";

const DspPagination = ({ onChange, totalRows }:any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    setCurrentPage(1);
  }, [totalRows]);

  const totalPages = Math.ceil(totalRows / itemsPerPage);

  const handlePageChange = (newPage:any) => {
    const newOffset = (newPage - 1) * itemsPerPage;
    setCurrentPage(newPage);
    onChange(newOffset);
  };

  return (
    <div className="pull-left pagination">
      <ul>
        <li onClick={() => handlePageChange(1)}>First</li>
        <li onClick={() => handlePageChange(currentPage - 1)}>&laquo;</li>

        {Array.from({ length: totalPages }).map((_, index) => (
          <li
            key={index + 1}
            className={currentPage === index + 1 ? 'active' : ''}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </li>
        ))}

        <li onClick={() => handlePageChange(currentPage + 1)}>&raquo;</li>
        <li onClick={() => handlePageChange(totalPages)}>Last</li>
      </ul>
    </div>
  );
};

export default DspPagination;