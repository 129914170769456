import { CommonUtilities } from "../../commonUtils";
import { Modal } from 'react-responsive-modal';
import React, { Suspense, useState, useEffect } from "react";
import Pagination from "components/Header/Pagination";
import ClientProfileSevices from "./clientProfileServices";

export default function AuditTrail() {


    const [openFirst, setOpenFirst] = React.useState(false);

    const Row = [
        'Chronological Order',
        'Contract Management',
        'Invoice Audit',
        'Email Statement',
        'Suggested Payment',
        'Mechanical',
        'Track Management',
        'User Management',
        'MetaData',
        'Data Load',
        'Data Management',
        'iTunes API Service',
        'System'
    ]

    const [Chronological, setChronological] = useState(true);
    const [ChronologicalListing, setChronologicalListing] = useState<any>([]);
    const [ChronologicalListingCreators, setChronologicalListingCreators] = useState<any>([]);
    const [contractMgt, setcontractMgt] = useState(false);
    const [contractMgtCount, setcontractMgtCount] = useState(0);
    const [contractMgtListing, setcontractMgtListing] = useState<any>([]);

    const [auditInvoice, setauditInvoice] = useState(false);
    const [auditInvoiceCount, setauditInvoiceCount] = useState(0);
    const [auditInvoiceListing, setauditInvoiceListing] = useState<any>([]);

    const [emailStatement, setemailStatement] = useState(false);
    const [emailStatementCount, setemailStatementCount] = useState(0);
    const [emailStatementListing, setemailStatementListing] = useState<any>([]);

    const [SuggestedPayment, setSuggestedPayment] = useState(false);
    const [SuggestedPaymentCount, setSuggestedPaymentCount] = useState(0);
    const [SuggestedPaymentListing, setSuggestedPaymentListing] = useState<any>([]);


    const [mechanical, setmechanical] = useState(false);
    const [mechanicalCount, setmechanicalCount] = useState(0);
    const [mechanicalListing, setmechanicalListing] = useState<any>([]);

    const [TrackManagement, setTrackManagement] = useState(false);
    const [TrackManagementCount, setTrackManagementCount] = useState(0);
    const [TrackManagementListing, setTrackManagementListing] = useState<any>([]);

    const [MetaData, setMetaData] = useState(false);
    const [MetaDataCount, setMetaDataCount] = useState(0);
    const [MetaDataListing, setMetaDataListing] = useState<any>([]);

    const [UserManagement, setUserManagement] = useState(false);
    const [UserManagementCount, setUserManagementCount] = useState(0);
    const [UserManagementListing, setUserManagementListing] = useState<any>([]);


    const [DataLoad, setDataLoad] = useState(false);
    const [DataLoadCount, setDataLoadCount] = useState(0);
    const [DataLoadListing, setDataLoadListing] = useState<any>([]);

    const [DataManagement, setDataManagement] = useState(false);
    const [DataManagementCount, setDataManagementCount] = useState(0);
    const [DataManagementListing, setDataManagementListing] = useState<any>([]);

    const [APIService, setAPIService] = useState(false);
    const [APIServiceCount, setAPIServiceCount] = useState(0);
    const [APIServiceListing, setAPIServiceListing] = useState<any>([]);

    const [System, setSystem] = useState(false);
    const [SystemCount, setSystemCount] = useState(0);
    const [SystemListing, setSystemListing] = useState<any>([]);


    const [searchFor, setsearchFor] = useState("");
    var payload = {
        "search": "none",
        "operation": "Update",
        "pageNumber": 1,
        "pageSize": 10,
        "searchDesc": "none"
    }

    const apiCall = (param: any) => {
        if (param == 1) {
            payload.search = searchFor.length > 0 ? searchFor : "";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setChronologicalListing(values) })

            payload.operation = "Create";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setChronologicalListingCreators(values) })
        } if (param == 2) {
            payload.search = searchFor.length > 0 ? searchFor : "Contract";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setcontractMgtListing(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setcontractMgtCount(values) })

        } if (param == 3) {
            payload.search = searchFor.length > 0 ? searchFor : "Invoice";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setauditInvoiceListing(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setauditInvoiceCount(values) })

        } if (param == 4) {
            payload.search = searchFor.length > 0 ? searchFor : "Email Statement";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setemailStatementListing(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setemailStatementCount(values) })

        } if (param == 5) {
            payload.search = searchFor.length > 0 ? searchFor : "Suggested Payment";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setSuggestedPaymentListing(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setSuggestedPaymentCount(values) })

        } if (param == 6) {
            payload.search = searchFor.length > 0 ? searchFor : "Mechanical";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setSuggestedPaymentListing(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setmechanicalCount(values) })

        } if (param == 7) {
            payload.search = searchFor.length > 0 ? searchFor : "Track Management";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setTrackManagementListing(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setTrackManagementCount(values) })

        } if (param == 8) {
            payload.search = searchFor.length > 0 ? searchFor : "User Management";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setUserManagement(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setUserManagementCount(values) })

        } if (param == 9) {
            payload.search = searchFor.length > 0 ? searchFor : "MetaData";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setMetaDataListing(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setMetaDataCount(values) })

        } if (param == 10) {
            payload.search = searchFor.length > 0 ? searchFor : "DataLoad";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setDataLoadListing(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setDataLoadCount(values) })

        } if (param == 11) {
            payload.search = searchFor.length > 0 ? searchFor : "DataManagement";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setDataLoadListing(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setDataManagementCount(values) })

        } if (param == 12) {
            payload.search = searchFor.length > 0 ? searchFor : "iTunesAPIService";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setAPIServiceListing(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setAPIServiceCount(values) })

        } if (param == 13) {
            payload.search = searchFor.length > 0 ? searchFor : "System";
            payload.operation = "none";
            ClientProfileSevices.AuditTrailChronological(payload).then((values) => { setSystemListing(values) })
            ClientProfileSevices.AuditTrailCount(payload).then((values) => { setSystemCount(values) })

        }
    }

    const handlecontractMgt = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(2)
    }

    const handleauditInvoice = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(3)
    }

    const handleEmailStatement = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(4)
    }

    const handleSuggestedPayment = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(5)
    }

    const handlemechanical = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(6)
    }
    const handleTrackManagement = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(7)
    }

    const handleUserManagement = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(8)
    }

    const handleMetaData = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(9)
    }

    const handleDataLoad = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(10)
    }


    const handleDataManagement = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(11)
    }

    const handleAPIService = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(12)
    }
    const handleSystem = (event: any) => {
        console.log(event)
        payload.pageNumber = event.pageNumber;
        payload.pageSize = event.pageSize;
        apiCall(13)
    }

    return (

        <div className="pull-left">
            <div className="pull-left" onClick={() => { setOpenFirst(true); apiCall(1); }}>
                <div className="box-primary ms text-center iconLogo pull-left  mrl">
                    <i className="box icon-line-chart"></i>
                    <span className="m0 pts ">Audit Trail</span>
                </div>
            </div>

            <Modal open={openFirst} onClose={() => setOpenFirst(false)} center>
                <div className="box">
                    <div className="box-content">
                        <Suspense fallback={<div>Loading</div>}>


                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > Chronological Order </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => setChronological(!Chronological)}>
                                            <i className={Chronological == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {Chronological == true && <div className="box-content">
                                    <div className="row-fluid ws-12">

                                        <div className="ws-6">
                                            <h1> Top 10 Modifications  </h1>
                                            <div className="table-responsive">
                                                {(ChronologicalListing.length >= 10) && <table className="table" >
                                                    <thead>
                                                        <tr>
                                                            <th>User</th>
                                                            <th>Description</th>
                                                            <th>Category</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ChronologicalListingCreators.map((value: any) => {
                                                            return (
                                                                <tr>
                                                                    <td >{value.username}</td>
                                                                    <td >{value.description}</td>
                                                                    <td >{value.category}</td>
                                                                    <td >{value.date}</td>
                                                                </tr>)
                                                        })}
                                                    </tbody>
                                                </table>}
                                            </div>

                                            {(ChronologicalListing.length == 0) && <div className="alert alert-info" >No Data ModificationLog to display </div>}
                                        </div>
                                        <div className="ws-6 pll">
                                            <h1> Top 10 Creations  </h1>
                                            <div className="table-responsive">
                                                {(ChronologicalListing.length >= 10) && <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>User</th>
                                                            <th>Description</th>
                                                            <th>Category</th>
                                                            <th>Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ChronologicalListing.map((value: any) => {
                                                            return (
                                                                <tr>
                                                                    <td >{value.username}</td>
                                                                    <td >{value.description}</td>
                                                                    <td >{value.category}</td>
                                                                    <td >{value.date}</td>
                                                                </tr>)
                                                        })}
                                                    </tbody>
                                                </table>}
                                            </div>
                                            {(ChronologicalListing.length == 0) && <div className="alert alert-info" >No Data Creation Log to display</div>}
                                        </div>
                                    </div>

                                </div>}

                            </div>

                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > Contract Managementr </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setcontractMgt(!contractMgt); apiCall(2) }}>
                                            <i className={contractMgt == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {contractMgt == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" type="text" onChange={(e: any) => setsearchFor(e.target.value)} placeholder="Search Contract Management" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(2) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(2) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(contractMgtListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {contractMgtListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(contractMgtListing.length == 0) && <div className="alert alert-info" >No Contract Management Log to display  </div>}

                                            {
                                                (() => {
                                                    if (contractMgtCount && contractMgtCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handlecontractMgt} totalRows={contractMgtCount} /></div>
                                                    }
                                                })()

                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>

                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > Invoice Audit </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setauditInvoice(!auditInvoice); apiCall(3) }}>
                                            <i className={auditInvoice == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {auditInvoice == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" onChange={(e: any) => setsearchFor(e.target.value)} type="text" placeholder="Search Contract Management" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(3) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(3) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(auditInvoiceListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {auditInvoiceListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(auditInvoiceListing.length == 0) && <div className="alert alert-info" >No Contract Management Log to display  </div>}

                                            {
                                                (() => {
                                                    if (auditInvoiceCount && auditInvoiceCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handlecontractMgt} totalRows={auditInvoiceCount} /></div>
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>

                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > Email Statement </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setemailStatement(!emailStatement); apiCall(4) }}>
                                            <i className={emailStatement == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {emailStatement == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" onChange={(e: any) => setsearchFor(e.target.value)} type="text" placeholder="Search Contract Management" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(4) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(4) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(emailStatementListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {emailStatementListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(emailStatementListing.length == 0) && <div className="alert alert-info" >No Contract Management Log to display  </div>}

                                            {
                                                (() => {
                                                    if (emailStatementCount && emailStatementCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handleEmailStatement} totalRows={emailStatementCount} /></div>
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>

                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > Suggested Payment </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setSuggestedPayment(!SuggestedPayment); apiCall(5) }}>
                                            <i className={SuggestedPayment == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {SuggestedPayment == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" onChange={(e: any) => setsearchFor(e.target.value)} type="text" placeholder="Search Contract Management" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(5) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(5) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(SuggestedPaymentListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {SuggestedPaymentListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(SuggestedPaymentListing.length == 0) && <div className="alert alert-info" >No Contract Management Log to display  </div>}

                                            {
                                                (() => {
                                                    if (SuggestedPaymentCount && SuggestedPaymentCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handleSuggestedPayment} totalRows={SuggestedPaymentCount} /></div>
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>

                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > Mechanical </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setmechanical(!mechanical); apiCall(6) }}>
                                            <i className={mechanical == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {mechanical == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" onChange={(e: any) => setsearchFor(e.target.value)} type="text" placeholder="Search Contract Management" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(6) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(6) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(mechanicalListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {mechanicalListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(mechanicalListing.length == 0) && <div className="alert alert-info" >No Contract Management Log to display  </div>}


                                            {
                                                (() => {
                                                    if (mechanicalCount && mechanicalCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handlemechanical} totalRows={mechanicalCount} /></div>
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>
                            {/* // */}
                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > Track Management </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setTrackManagement(!TrackManagement); apiCall(7) }}>
                                            <i className={TrackManagement == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {TrackManagement == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" onChange={(e: any) => setsearchFor(e.target.value)} type="text" placeholder="Search Contract Management" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(7) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(7) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(TrackManagementListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {TrackManagementListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(TrackManagementListing.length == 0) && <div className="alert alert-info" >No Contract Management Log to display  </div>}

                                            {
                                                (() => {
                                                    if (TrackManagementCount && TrackManagementCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handleTrackManagement} totalRows={TrackManagementCount} /></div>
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>
                            {/* UserManagement */}
                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > User Management </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setUserManagement(!UserManagement); apiCall(8) }}>
                                            <i className={UserManagement == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {UserManagement == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" onChange={(e: any) => setsearchFor(e.target.value)} type="text" placeholder="Search User Management" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(8) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(8) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(UserManagementListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {UserManagementListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(UserManagementListing.length == 0) && <div className="alert alert-info" >No User Management Log to display  </div>}

                                            {
                                                (() => {
                                                    if (UserManagementCount && UserManagementCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handleUserManagement} totalRows={UserManagementCount} /></div>
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>
                            {/* MetaData */}
                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > MetaData </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setMetaData(!MetaData); apiCall(9) }}>
                                            <i className={MetaData == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {MetaData == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" onChange={(e: any) => setsearchFor(e.target.value)} type="text" placeholder="Search MetaData" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(9) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(9) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(MetaDataListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {MetaDataListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(MetaDataListing.length == 0) && <div className="alert alert-info" >No MetaData Log to display  </div>}

                                            {
                                                (() => {
                                                    if (MetaDataCount && MetaDataCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handleMetaData} totalRows={MetaDataCount} /></div>
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>
                            {/* DataLoad */}
                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > DataLoad </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setDataLoad(!DataLoad); apiCall(10) }}>
                                            <i className={DataLoad == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {DataLoad == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" onChange={(e: any) => setsearchFor(e.target.value)} type="text" placeholder="Search DataLoad" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(10) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(10) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(DataLoadListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {DataLoadListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(DataLoadListing.length == 0) && <div className="alert alert-info" >No DataLoad Log to display  </div>}

                                            {
                                                (() => {
                                                    if (DataLoadCount && DataLoadCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handleDataLoad} totalRows={DataLoadCount} /></div>
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>

                            {/* DataManagement */}
                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > Data Management </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setDataManagement(!DataManagement); apiCall(11) }}>
                                            <i className={DataManagement == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {DataManagement == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" onChange={(e: any) => setsearchFor(e.target.value)} type="text" placeholder="Search Data Management" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(11) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(11) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(DataManagementListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {DataManagementListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(DataManagementListing.length == 0) && <div className="alert alert-info" >No Data Managementt Log to display  </div>}

                                            {
                                                (() => {
                                                    if (DataManagementCount && DataManagementCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handleDataManagement} totalRows={DataManagementCount} /></div>
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>
                            {/* iTunes API Service */}
                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > iTunes API Service </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setAPIService(!APIService); apiCall(12) }}>
                                            <i className={APIService == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {APIService == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" onChange={(e: any) => setsearchFor(e.target.value)} type="text" placeholder="Search API Service" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(12) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(12) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(APIServiceListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {APIServiceListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(APIServiceListing.length == 0) && <div className="alert alert-info" >No APIService Log to display  </div>}

                                            {
                                                (() => {
                                                    if (APIServiceCount && APIServiceCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handleAPIService} totalRows={APIServiceCount} /></div>
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>
                            {/* System */}
                            <div className="box mt">
                                <div className="box-header well p0 pt prs">
                                    <h2 className="ws-12 m0 pl full-width">
                                        <span className="pull-left cursor-hand" > System </span>
                                        <button className="btn-success hover-box btn-xs badge pl pr pull-right p0 mr" onClick={() => { setSystem(!System); apiCall(13) }}>
                                            <i className={System == false ? "icon-plus1" : "icon-minus1"}></i>
                                        </button>
                                    </h2>
                                </div>

                                {System == true && <div className="box-content">
                                    <div className="form-format ws-12">
                                        <div className="label-entries ws-4">
                                            <input className="form-control" id="Text1" name="searchFor" onChange={(e: any) => setsearchFor(e.target.value)} type="text" placeholder="Search System" />
                                        </div>
                                        <div className="ws-6">
                                            <a className="btn btn-success" onClick={() => { apiCall(13) }}><i className="icon-search"></i> Search </a>
                                            <a className="btn btn-pink" onClick={() => { setsearchFor(""); apiCall(13) }}>   <i className="icon-reload"></i> Refresh</a>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row-fluid">
                                        <div className="table-responsive">
                                            {(SystemListing.length >= 10) && <table className="table" >
                                                <thead>
                                                    <tr>
                                                        <th>User</th>
                                                        <th>Description</th>
                                                        <th>Operation</th>
                                                        <th>Method</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {SystemListing.map((value: any) => {
                                                        return (
                                                            <tr>
                                                                <td >{value.username}</td>
                                                                <td >{value.description}</td>
                                                                <td >{value.operation}</td>
                                                                <td >{value.method}</td>
                                                                <td >{value.date}</td>
                                                            </tr>)
                                                    })}
                                                </tbody>
                                            </table>}

                                            {(SystemListing.length == 0) && <div className="alert alert-info" >No System Log to display  </div>}

                                            {
                                                (() => {
                                                    if (SystemCount && SystemCount > 10) {
                                                        return <div className="ptl"><Pagination onChange={handleSystem} totalRows={SystemCount} /></div>
                                                    }
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>}

                            </div>

                        </Suspense>
                    </div>
                </div>
            </Modal>

        </div>

    )

}