
import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import directoryServices from "../directoryServices";
import LoadFile from './loadfile';
import TableConfirmation from './table_clear_confirmation';
import FileValidation from './FileValidation';
import FileLog from './fileLog';

export default function AssetUploader(param: any) {

    let [ViewUploadListing, setViewUploadList] = useState<any[]>([]);
    let [searchForField, setsearchForField] = useState<any>([]);
    let [ViewUploadTotalRow, setViewUploadTotalRow] = useState(0);
    let [searchListing, setSearchListing] = useState<any[]>([]);
    let [viewAllStatus, setviewAllStatusStatus] = useState(false);
    let [searchForStatus, setSearchForStatus] = useState(false);
    const [openPage, setOpenPage] = useState(param.open);
    let [searchTotalRow, setSearchTotalRow] = useState(0);
    const [fileuploadStatus, setfileuploadStatus] = useState(false);
    const [collapsedSearch, setcollapsedSearch] = useState(false);
    const [LoadFileStatus, setLoadFileStatus] = useState(false);
    const [fileToUpload, setfileToUpload] = useState(null);
    const [openLoadFile, setopenLoadFile] = useState(0);
    const [dataCounting, setdataCounting] = useState(0);
    let [EditValue, setEditValue] = useState<any>([]);
    let [openEdit, setOpenEdit] = useState(false)

    const closePage = () => {
        setOpenPage(false)
        param.CloseUploader("close");
    }

    //console.log(param)
    useEffect(() => {
        searchListing = [];
        setSearchListing(searchListing)
        setcollapsedSearch(false)
        setfileToUpload(null)
        setSearchTotalRow(0); setViewUploadTotalRow(0)
        setSearchForStatus(false)
        setviewAllStatusStatus(false)
        editUploader([])
        setOpenEdit(false)
        dataCount();
        setsearchForField([])
    }, [param.open]);


    var searchForPayload = {
        AssetType: "T", pageNumber: "1", pageSize: "10"
    }
    const searchFor = () => {
        console.log(searchForPayload)
        if (param.type == 'Tracks') {
            searchForPayload = {...searchForField , ...searchForPayload};
            directoryServices.assetUploaderList(searchForPayload).then((values: any) => {
                setSearchListing(values);
                setSearchTotalRow(values[0] ? values[0].totalRows : 0)
            })
        }

        if (param.type == 'Releases') {
            searchForPayload.AssetType = 'R';
            searchForPayload = {...searchForField , ...searchForPayload};
            directoryServices.assetUploaderList(searchForPayload).then((values: any) => {
                setSearchListing(values);
                setSearchTotalRow(values[0] ? values[0].totalRows : 0)
            })
        }

        if (param.type == 'Songs') {
            searchForPayload.AssetType = 'S';
            searchForPayload = {...searchForField , ...searchForPayload};
            directoryServices.assetUploaderList(searchForPayload).then((values: any) => {
                setSearchListing(values);
                setSearchTotalRow(values[0] ? values[0].totalRows : 0);
            })
        }

        if (param.type == 'ReleasesTracks') {
            searchForPayload = {...searchForField , ...searchForPayload};
            directoryServices.releaseTracklisting(searchForPayload).then((values: any) => {
                setSearchListing(values);
                setSearchTotalRow(values[0] ? values[0].totalRows : 0);
            })
        }

        if (param.type == 'DeaperPrice') {
            searchForPayload = {...searchForField , ...searchForPayload};
            directoryServices.dealerPriceListing(searchForPayload).then((values: any) => {
                setSearchListing(values);
                setSearchTotalRow(values[0] ? values[0].totalRows : 0);
            })
        }

        if (param.type == 'Bundle') {
            searchForPayload = {...searchForPayload , ...searchForField};
            directoryServices.BundleList(searchForPayload).then((values: any) => {
                setSearchListing(values);
                setSearchTotalRow(values[0] ? values[0].totalRows : 0);
            })
            console.log(searchForPayload)
            console.log(searchForField)
        }
        
        setSearchForStatus(true)
    }

    const onChangeFileHandler = (e :any) => {             
        
        //searchForField[values.header.replace(/\s/g, '')] 
                
        var field_name = e.target.name.replace(/\s/g, '')
        var field_value = e.target.value.replace(/\s/g, '')

        searchForField[field_name] = (searchForField[field_name]? searchForField[field_name] : "") + field_value;
        searchForField = searchForField;
        setsearchForField({ ...searchForField, ...{ [field_name]: field_value } });

        console.log(searchForField) 
    }
    var searchForExportPayload = {
        AssetType: "T", pageNumber: "1", pageSize: "10", exportType: "AssetDetails"
    }
    const searchForExport = () => {

        if (param.type == 'Tracks') {
            directoryServices.assetUploaderExport(searchForExportPayload);
        }

        if (param.type == 'Releases') {
            searchForExportPayload.AssetType = 'R';
            directoryServices.assetUploaderExport(searchForExportPayload);
        }

        if (param.type == 'Songs') {
            searchForExportPayload.AssetType = 'S';
            directoryServices.assetUploaderExport(searchForExportPayload);
        }

        if (param.type == 'TraReleasesTrackscks') {
            directoryServices.releaseTrackExport(searchForExportPayload);
        }

        if (param.type == 'DeaperPrice') {
            directoryServices.dealerPriceExport(searchForExportPayload);
        }

        if (param.type == 'Bundle') {
            directoryServices.BundleListExport({ exportype: "BundleDetails" });
        }

    }

    const dataCount = () => {

        if (param.type == 'Tracks') {
            directoryServices.assetUploaderCount({ type: "Tracks" }).then((res: any) => { setdataCounting(res); })
        }

        if (param.type == 'Releases') {
            directoryServices.assetUploaderCount({ type: "Releases" }).then((res: any) => { setdataCounting(res); })
        }

        if (param.type == 'Songs') {
            directoryServices.assetUploaderCount({ type: "Songs" }).then((res: any) => { setdataCounting(res); })
        }

        if (param.type == 'ReleasesTracks') {
            directoryServices.assetUploaderCount({ type: "releaseTrack" }).then((res: any) => { setdataCounting(res); })
        }

        if (param.type == 'DeaperPrice') {
            directoryServices.assetUploaderCount({ type: "Dealerprice" }).then((res: any) => { setdataCounting(res); })
        }

        if (param.type == 'Bundle') {
            directoryServices.bundleSelect(1,10).then((res: any) => { setdataCounting(res[0].totalRows? res[0].totalRows : 0); })
        }

    }

    var viewPayload = {
        pageNumber: "1", pageSize: "10"
    }
    const ViewUploadList = () => {
        if (param.type == 'Tracks') {
            directoryServices.assetUploaderTrackList(viewPayload).then((values: any) => {
                setViewUploadList(values)

                setViewUploadTotalRow(values[0] ? values[0].totalRows : 0)
                console.log(values)
            })
        }

        if (param.type == 'Releases') {
            directoryServices.assetUploaderReleaseList(viewPayload).then((values: any) => {
                setViewUploadList(values)

                setViewUploadTotalRow(values[0] ? values[0].totalRows : 0)
                console.log(values)
            })
        }

        if (param.type == 'Songs') {
            directoryServices.songUploadList(viewPayload).then((values: any) => {
                setViewUploadList(values)

                setViewUploadTotalRow(values[0] ? values[0].totalRows : 0)
                console.log(values)
            })
        }

        if (param.type == 'ReleasesTracks') {

            directoryServices.releaseTrackUploadList(viewPayload).then((values: any) => {
                setViewUploadList(values)

                setViewUploadTotalRow(values[0] ? values[0].totalRows : 0)
                console.log(values)
            })
        }

        if (param.type == 'DeaperPrice') {
            directoryServices.dealerPriceUploadList(viewPayload).then((values: any) => {
                setViewUploadList(values)

                setViewUploadTotalRow(values[0] ? values[0].totalRows : 0)
                console.log(values)
            })
        }
        if (param.type == 'Bundle') {
            directoryServices.BundleUploadList(viewPayload).then((values: any) => {
                setViewUploadList(values)

                setViewUploadTotalRow(values[0] ? values[0].totalRows : 0)
                console.log(values)
            })
        }
        setviewAllStatusStatus(true)
    }

    const ViewUploadExport = () => {
        if (param.type == 'Tracks') {
            directoryServices.assetUploaderTrackExport(searchForExportPayload);
        }

        if (param.type == 'Releases') {
            directoryServices.assetUploaderReleaseExport(searchForExportPayload);
        }

        if (param.type == 'Songs') {
            directoryServices.assetUploaderSongExport(searchForExportPayload);
        }

        if (param.type == 'TraReleasesTrackscks') {
            directoryServices.releaseTrackUploadExport(searchForExportPayload);
        }

        if (param.type == 'DeaperPrice') {
            directoryServices.dealerPriceUploadExport(searchForExportPayload);
        }

        /* if (param.type == 'Bundle') {
            directoryServices.dealerPriceUploadExport(searchForExportPayload);
        } */

    }

    const fileupload = (event: any) => {
        console.log(event.target.files)
        console.log(event)
        const fileName = event.target.files[0];
        if (fileName) {
            setfileToUpload(fileName)
            setfileuploadStatus(true)
        }
    }

    const onChangeLoadFile = (e: any) => {

        if (e == 1) {
            //Appen
            uploadData();
        }
        if (e == 2) {
            //clear
            clearUpload();
            uploadData();
        }
        if (e == 3) {
            //load
            uploadData();
        }

        console.log(e)
        setLoadFileStatus(false)
        ViewUploadList();
    }

    const uploadData = () => {

        if (param.type == 'Tracks') {
            directoryServices.trackUpload(fileToUpload).then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'Releases') {
            directoryServices.releaseUpload(fileToUpload).then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'Songs') {
            directoryServices.songUpload(fileToUpload).then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'ReleasesTracks') {
            directoryServices.trackUpload(fileToUpload).then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'DeaperPrice') {
            directoryServices.trackUpload(fileToUpload).then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'Bundle') {
            directoryServices.BundleUpload(fileToUpload).then((res: any) => { ViewUploadList(); })
        }

    }

    const clearUpload = () => {
        if (param.type == 'Tracks') {
            directoryServices.clearUploadTrack().then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'Releases') {
            directoryServices.clearUploadRelease().then((res: any) => { ViewUploadList(); })
        }
        if (param.type == 'Songs') {
            directoryServices.clearUploadSongs().then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'TraReleasesTrackscks') {
            directoryServices.clearUploadReleaseTrack().then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'DeaperPrice') {
            directoryServices.clearUploadDealerPrice().then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'Bundle') {
            directoryServices.BundleClear().then((res: any) => { ViewUploadList(); })
        }

    }

    const generateMissingUPC = () => {
        directoryServices.generateMissingUPC();
        ViewUploadList();
    }

    const generateMissingUPCISRC = () => {
        directoryServices.generateMissingUPCISRC();
        ViewUploadList();
    }

    const generateMissingISRC = () => {
        directoryServices.generateMissingISRC();
        ViewUploadList();
    }

    const onClearConfirmation = (e: any) => {
        console.log(e)
        if (e == 1) {
            //clearUpload
            clearUpload();
        }
        setLoadFileStatus(false)
    }

    const onFileValidation = (e: any) => {
        setLoadFileStatus(false);
        ViewUploadList();
    }

    const onChangeLogFile = (e: any) => {
        setLoadFileStatus(false)
    }

    const editUploader = (e: any) => {
        EditValue = e;
        setEditValue(EditValue)
        setOpenEdit(true)
    }

    const deleteUploader = (e: any) => {
        console.log(e)
        if (param.type == 'Tracks') {
            directoryServices.assetUploaderTrackDelete(e.trackUploadId).then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'Releases') {
            directoryServices.assetUploaderReleaseDelete(e.releaseUploadId).then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'ReleasesTracks') {
            directoryServices.assetUploaderReleaseTrackDelete(e.releaseTracksUploadId).then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'DeaperPrice') {
            directoryServices.assetUploaderDealerPriceDelete(e.dealerPriceUploadID).then((res: any) => { ViewUploadList(); })
        }

        ViewUploadList();
    }

    const saveUploader = (e: any) => {
        if (param.type == 'Tracks') {
            directoryServices.assetUploaderTrackSave(EditValue).then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'Releases') {
            directoryServices.assetUploaderReleaseSave(EditValue).then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'ReleasesTracks') {
            directoryServices.assetUploaderReleaseTrackSave(EditValue).then((res: any) => { ViewUploadList(); })
        }

        if (param.type == 'DeaperPrice') {
            directoryServices.assetUploaderDealerPriceSave(EditValue).then((res: any) => { ViewUploadList(); })
        }

    }
    const EditHandler = (e: any) => {
        var field_name = e.target.name;
        var field_value = e.target.value;
        setEditValue({ ...EditValue, ...{ [field_name]: field_value } });
    }
    return (
        <>
            <Drawer open={param.open}
                onClose={closePage}
                direction='right'
                className='react-drawer-left allowHscroll' style={{ width: "100%" }}>

                <div className="ws-6 pull-right box" style={{ height: "100%" }}>

                    <div className="box-header bg-blue">
                        <h1>Upload {param.type}</h1>
                    </div>

                    <div className='box-content ws-12'>

                        <div className='box'>
                            <div className="box-header well">
                                <h2 className="ws-6"><i className="icon-music1"></i> Search</h2>

                                <div className="ws-6">
                                    <button className="btn btn-grey pull-right" onClick={() => setcollapsedSearch(!collapsedSearch)}>
                                        {(collapsedSearch == true) && <i className="icon-angle-up"></i>}
                                        {(collapsedSearch == false) && <i className="icon-angle-down"></i>}
                                    </button>
                                </div>

                            </div>
                            
                            {(collapsedSearch == true) && <><div className="row-fluid pLarge">

                                {
                                    directoryServices.columnName?.map((values: any) => {

                                        if ((values.route == param.type) && (values.type == "textField")) {
                                            return (
                                                <div className="ws-3">
                                                    <div className="form-format">
                                                        <label className="control-label" > {values.header}</label>
                                                        <div className="label-entries ws-10">
                                                            <input className="form-control" key={values.header} value={searchForField[values.header.replace(/\s/g, '')] ? searchForField[values.header.replace(/\s/g, '')] : ""}  
                                                                    name={values.header}  onChange={onChangeFileHandler} type="text" placeholder={values.header} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    })
                                }
                            </div>

                                <div className="box-footer pull-right">

                                    <button className="btn btn-success btn" onClick={() => searchFor()}>
                                        <i className="icon-search"></i> Search
                                    </button>
                                    <button className="btn btn-pink btn" ng-click="searchUploader={}">
                                        <i className="icon-refresh"></i> Reset
                                    </button>
                                    <button className="btn btn-primary btn" onClick={() => searchForExport()}>
                                        <i className="icon-download1"></i>Export
                                    </button>
                                </div></>}

                            <div className="" >

                                {(searchForStatus) && <div className="ws-12 box">
                                    <div className="box-header well">
                                        <h2 className=""><i className="icon-info"></i> {searchTotalRow} record(s) found</h2>
                                    </div>

                                    <div className="box-content">
                                        {(searchTotalRow == 0) && <div className="alert alert-info ws-12">No data to display for this search</div>}

                                        {(searchTotalRow > 0) && <div className="table-responsive allowVscroll ws-12">
                                            <table className="table box">
                                                <thead>
                                                    <tr className='forceSingleLine'>

                                                        {
                                                            directoryServices.columnName?.map((values: any) => {

                                                                if (values.route == param.type) {
                                                                    return (
                                                                        <th className='forceSingleLine'>{values.header}</th>
                                                                    )
                                                                }

                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        searchListing?.map((values: any) => {

                                                            return (
                                                                <tr className=''>
                                                                    {
                                                                        directoryServices.columnName?.map((value: any) => {
                                                                            if (value.route == param.type) {
                                                                                return (<td className='forceSingleLine'>{values[value.mapping]}</td>)
                                                                            }
                                                                        })
                                                                    }

                                                                </tr>
                                                            )

                                                        })
                                                    }


                                                </tbody>
                                            </table>
                                        </div>}

                                    </div>
                                </div>}

                            </div>

                        </div>

                        <div className='box mtl'>
                            <div className="box-header well">
                                <h2 className="ws-6 "><i className="icon-upload"></i> File Details</h2>
                                <div className="ws-6">
                                    <button className="btn-primary pull-right" onClick={() => { setLoadFileStatus(true); setopenLoadFile(4) }}><i className="icon-history"></i> </button>
                                    <button className="btn btn-pink pull-right" onClick={ViewUploadList}><i className="icon-refresh2"></i> Refresh</button>
                                </div>
                            </div>

                            <div className='ws-12 ptl'>
                                <div className="ws-3">
                                    <div className="label-entries">
                                        <label className="btn btn-success btn-file btn-sm">
                                            <i className="icon-upload"></i>Upload File
                                            <input type="file" accept='pdf/*' key="file" onChange={(e) => fileupload(e)} className="hide" />
                                        </label>

                                        {(fileuploadStatus) && <a className="btn btn-success btn-sm" onClick={() => { setLoadFileStatus(true); setopenLoadFile(1) }}><i className="icon-spinner10"></i> Load</a>}

                                    </div>
                                </div>

                                <div className="ws-6">
                                    <div className="progress">
                                        <div className="btn-lightBlue" role="progressbar"> 0 %</div>
                                    </div>
                                </div>
                            </div>

                            {(dataCounting>0) && <div className="box-footer pull-right">

                                <button className="btn btn-warning pull-left btn-sm" onClick={() => { setLoadFileStatus(true); setopenLoadFile(2) }}><i className="icon-trashcan"></i></button>
                                <button className="btn btn-success" onClick={ViewUploadList}><i className="icon-search"></i> View All</button>
                                <button className="btn btn-danger" onClick={() => { setLoadFileStatus(true); setopenLoadFile(3) }}><i className="icon-tick"></i> Validate</button>

                                {(param.type == 'Release') && <button className="btn btn-success" onClick={generateMissingUPC}>Generate UPC </button>}
                                {(param.type == 'Tracks') && <button className="btn btn-success" onClick={generateMissingISRC}> Generate ISRC </button>}
                                {(param.type == 'ReleasesTracks') && <button className="btn btn-success" onClick={generateMissingUPCISRC}> Generate UPC/ISRC </button>}

                                <div className="ws-2 pull-right hide">
                                    <input className="btn pull-right" key="Master" type="checkbox" />
                                    <span className="forceSingleLine ws-12 ">Release Code As Master</span>
                                </div>
                            </div>}
                        </div>

                        <div className='mtl'>
                            {(viewAllStatus) && <div className='box ws-12 mtl'>
                                <div className="box-header well">
                                    <h2 className="ws-10"><i className="icon-info"></i> {ViewUploadTotalRow} record(s) found</h2>
                                    {(ViewUploadTotalRow > 0) && (param.type != 'Bundle') && <button className="btn btn-primary btn pull-right" onClick={ViewUploadExport}><i className="icon-download1"></i>Export </button>}
                                </div>

                                <div className="box-content">
                                    {(ViewUploadTotalRow == 0) && <div className="alert alert-info ws-12">No data to display for this search</div>}

                                    {(ViewUploadTotalRow > 0) && <div className="table-responsive allowVscroll ws-12">
                                        <table className="table box">
                                            <thead>
                                                <tr className='forceSingleLine'>

                                                    {
                                                        directoryServices.columnUploadName?.map((values: any) => {

                                                            if (values.route == param.type) {
                                                                return (
                                                                    <th className='forceSingleLine'>{values.header}</th>
                                                                )
                                                            }
                                                        })
                                                    }

                                                    {(param.type != 'Bundle') && <th className='forceSingleLine' style={{ width: "150px" }}>Operation</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    ViewUploadListing?.map((values: any) => {

                                                        return (
                                                            <tr className=''>
                                                                {
                                                                    directoryServices.columnUploadName?.map((value: any) => {
                                                                        if (value.route == param.type) {
                                                                            return (<td className='forceSingleLine'>{values[value.mapping]}</td>)
                                                                        }
                                                                    })
                                                                }

                                                                {(param.type != 'Bundle') && <td style={{ width: "150px" }}>
                                                                    <a onClick={() => { editUploader(values); setOpenEdit(true); }} className="btn-sm btn-success"><i className="icon-edit1"></i> </a>
                                                                    <a onClick={() => { deleteUploader(values) }} className="btn-sm btn-danger"> <i className="icon-trashcan"></i> </a>
                                                                </td>}

                                                            </tr>
                                                        )

                                                    })
                                                }

                                                {(() => {
                                                    if ((openEdit == true) && (param.type != 'Bundle')) {
                                                        return (
                                                            <tr>
                                                                {
                                                                    directoryServices.columnUploadName?.map((value: any) => {
                                                                        if (value.route == param.type) {
                                                                            return (<td className='forceSingleLine'>
                                                                                <input key={value.mapping} value={EditValue[value.mapping]} name={value.mapping} onChange={(e: any) => EditHandler(e)} /> </td>)
                                                                        }
                                                                    })

                                                                }
                                                                <td style={{ width: "150px" }}>
                                                                    <a onClick={() => { saveUploader("") }} className="btn-sm btn-success"> Save</a>
                                                                    <a onClick={() => { setOpenEdit(false) }} className="btn-sm btn-danger">Cancel </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })()}

                                            </tbody>
                                        </table>
                                        {(param.type != 'Bundle') && <a onClick={() => { editUploader([]); setOpenEdit(true); }} className="btn btn-success mt pull-right">
                                            <i className="icon-plus1"></i><span className="hidden-tablet">New </span></a>}
                                    </div>}

                                </div>
                            </div>}
                        </div>

                        <div className="box-footer pull-right">
                            <button className="btn-pink pull-right" onClick={closePage}>
                                <i className="icon-close"></i> Close
                            </button>
                        </div>

                        {
                            (() => {

                                if (openLoadFile == 1) {
                                    return (<LoadFile onChangeLoadFile={onChangeLoadFile} open={LoadFileStatus} type={param.type} />)
                                }

                                if (openLoadFile == 2) {
                                    return (<TableConfirmation onClearConfirmation={onClearConfirmation} open={LoadFileStatus} />)
                                }

                                if (openLoadFile == 3) {
                                    return (<FileValidation onFileValidation={onFileValidation} open={LoadFileStatus} type={param.type} />)
                                }


                                if (openLoadFile == 3) {
                                    return (<FileValidation onFileValidation={onFileValidation} open={LoadFileStatus} type={param.type} />)
                                }

                                if ((param.type == 'Tracks' || param.type == 'Releases' || param.type == 'ReleasesTracks' || param.type == 'DeaperPrice' || param.type == 'Songs') && (openLoadFile == 4)) {
                                    return (<FileLog onChangeLogFile={onChangeLogFile} open={LoadFileStatus} type={"MD"} />)
                                }

                                if ((openLoadFile == 4) && (param.type == 'Bundle')) {
                                    return (<FileLog onChangeLogFile={onChangeLogFile} open={LoadFileStatus} type={"BN"} />)
                                }

                            })()
                        }

                    </div>
                </div>
            </Drawer>
        </>
    )
}