import { CommonUtilities } from "commonUtils";
import dashboardService from "components/Dashboard/dashboardServices";
import Creator from "components/directories/creator";
import Tooltip from "rc-tooltip";
import React, { Suspense, useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';
import Pagination from "components/Header/Pagination";
import DspPagination from "./DspPagination";


export default function SpotifyScraping({ onSelectItem }: any) {
    const [clientDetails, setClientDetails] = useState({
        grant_type: 'client_credentials',
        client_id: '0d95e2031b634ecba5986aae19d3d902',
        client_secret: 'f552e8c0809e40b29008cb62413eeedf',
        access_token: ''
    });

    const [showtab, setShowTab] = useState(false);
    const [resourceCall, setResourceCall] = useState(
        'https://api.spotify.com/v1/search?type=track&q='
    );
    const [requestJSON, setRequestJSON] = useState('Taylor Swift');
    const [resourceResponse, setResourceResponse] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {

        const authParameters = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: CommonUtilities.buildQueryString(clientDetails, true, false),
        };

        fetch('https://accounts.spotify.com/api/token', authParameters)
            .then((result) => result.json())
            .then((data) => setClientDetails({ ...clientDetails, access_token: data.access_token }));
    }, []);

    async function searchData() {

        const offset = (currentPage - 1) * itemsPerPage;
        const artistParameters = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + clientDetails.access_token,
            },
        };

        const url = `${resourceCall}${requestJSON}&offset=${offset}&limit=${itemsPerPage}`;

        const data = await fetch(url, artistParameters)
            .then((response) => response.json());

        setResourceResponse(data.tracks.items);

        const totalItems = data.tracks.total;
        setTotalItems(totalItems);
    }


    const handleLinkButtonClick = (item: any) => {

        onSelectItem(item);
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        searchData();
    };

    return (
        <div className="pull-left ws-12 full-width">
            <div className="box">
                <div className="box-header">Spotify</div>
                <div className="box-content mtl ptl">
                    <input type="text" className="ws-12 mtl" value={resourceCall} onChange={(e) => setResourceCall(e.target.value)} />

                    <input type="text" className="ws-12 mtll" value={requestJSON} onChange={(e) => setRequestJSON(e.target.value)} />

                </div>

                {resourceResponse && resourceResponse.length > 0 && (
                    <div>
                        {resourceResponse.map((item, index) => (
                            <div className="creatorImg pm pull-left" key={index}>

                                <div className="box shadowCoverPicture shadow miniCreatorImg">
                                    <div className="coverPicture">
                                        <div className="labelOverlay mt">

                                            <i  onClick={() => handleLinkButtonClick(item)} className="icon-LinkMade text-m white text-Shadow ml"></i>
                                            
                                        </div>

                                        <Tooltip placement="right" overlayStyle={{ width: '400px', height: '50px' }} trigger={['hover']} overlay={
                                            <div className='box'>
                                                <div className='box-header'>
                                                    Track Details
                                                </div>
                                                <div className='box-content'>
                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Track Name:</span> {item.name}
                                                    </div>
                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>ISRC:</span>{item.external_ids.isrc}
                                                    </div>
                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Artist Name:</span>{item.artists[0].name}
                                                    </div>

                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Type:</span>{item.type}
                                                    </div>

                                                    <div className="mb">
                                                        <span style={{ fontWeight: 'bold' }}>Popularity:</span>{item.popularity}
                                                    </div>

                                                </div>
                                            </div>}>


                                            <i className="icon-info1 pull-right positionAbsolute rightReset mr mt text-m white text-Shadow"> </i>

                                        </Tooltip>

                                        <img src={item.album.images[0].url} alt={item.name} />
                                        <div className="bottomOverlayLabel">
                                            <label className="control-label ws-12">{CommonUtilities.longCharacter(item.name, 18)}</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))}
                        
                        <DspPagination onChange={handlePageChange} totalRows={totalItems}/>
                    </div> 
                )}

                <div className="box-footer">
                    <button className="btn-success" onClick={() => searchData()}>
                        Request
                    </button>

                    
                   
                    
                </div>
            </div>
        </div>
    );
}