import { CommonUtilities }  from '../../commonUtils';

export default class globalSearchService  {

    static projectGrid() {
        var url = 'metadata/globalSearch/list?type=projectGrid';
        return CommonUtilities.getData(url);
    }
    //https://staging.onkorrect.com/Api/common/lookUpTypeList

    static lookUpTypeList() {
        var url = 'common/lookUpTypeList';
        return CommonUtilities.getData(url);
    }

    // https://staging.onkorrect.com/Api/projectUsers/userList?exclude=1&all=1
    static projectUsers (){
        var url = 'projectUsers/userList?exclude=1&all=1';
        return CommonUtilities.getData(url);
    }

    //https://staging.onkorrect.com/Api/metadata/globalSearch/list?type=contractGrid
    static contractGrid (){
        var url = 'metadata/globalSearch/list?type=contractGrid';
        return CommonUtilities.getData(url);
    }

    static globalGrid (type :any){
        var url = 'metadata/globalSearch/list?type=' +type;
        return CommonUtilities.getData(url);
    }

    //https://staging.onkorrect.com/Api/metadata/globalSearch/list?type=contactGrid
    static contactGrid (){
        var url = 'metadata/globalSearch/list?type=contactGrid';
        return CommonUtilities.getData(url);
    }

    //http://staging.onkorrect.com/Api/contracts/systemFormats?isPartFormatListed=true
    static systemFormatsList (){
        var url = 'contracts/systemFormats?isPartFormatListed=true';
        return CommonUtilities.getData(url);
    }
}
