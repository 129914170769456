import CommonSearch from "../../components/commonSearch/CommonSearch";
import globalSearchService from "../../components/commonSearch/globalSearchServices";
import React, { Suspense, useState, useEffect } from "react";
import directoryServices from "./directoryServices";
import Header from "../../components/Header/header";
import AssetUploader from "./Uploader/assetUploader";
import BundleSlider from "./Add Slider/AddBundleSlider";
import TrackSlider from "./Add Slider/AddTrackSlider";
import SongSlider from "./Add Slider/AddSongSlider";
import ReleaseSlider from "./Add Slider/AddReleaseSlider";
import LinkAssetBundle from "./Add Slider/linkAssetBundle";
import { CommonUtilities } from '../../commonUtils';

export default function AssetDirectory(param: any) {

    useEffect(() => {
        globalSearchService.lookUpTypeList()
            .then((res) => {
                global.lookUpTypeList = res;
            });
    }, []);

    let [activeAssetMenu, setActiveAssetMenu] = useState("Tracks");
    let [activeCategory, setActiveCategory] = useState("All");
    let [activeMenu, setActiveMenu] = useState("Assets");

    let [totalRow, settotalRow] = useState(0);
    let [directoryFullListing, setdirectoryFullListing] = useState<any[]>([]);
    let [directoryViewAll, setdirectoryViewAll] = useState<any[]>([]);
    let [viewAllList, setviewAllList] = useState(false);
    let [categoryList, setCategoryList] = useState<any[]>([]);
    const [perCategory, setperCategory] = useState(false);
    let [selectedDetail, setselectedDetail] = useState<any>([]);

    const [detailView, setdetailView] = useState(false);

    const [uploader, setuploader] = useState(0);

    let [detailSubTab, setdetailSubTab] = useState(1);


    var loop = 0;
    var pagination = {
        AssetCode: "",
        AssetContactName: "",
        AssetTitle: "",
        AssetType: "T",
        categoryName: "",
        display: "display",
        pageNumber: 1,
        pageSize: 10,
        searchFor: "",
        topSize: 5
    }

    useEffect(() => {
        setActiveCategory("All");
        if (loop == 0) {
            LandingListing();
            console.log(loop)
            loop = loop + 1;
        }

    }, [])

    var bundlePayload = {
        pageNumber: 1,
        pageSize: 10
    }
    const LandingListing = () => {
        setActiveAssetMenu(activeAssetMenu)
        if (activeAssetMenu == 'Tracks') {
            pagination.AssetType = 'T'
        } else if (activeAssetMenu == 'Songs') {
            pagination.AssetType = 'S'
        } else if (activeAssetMenu == 'Releases') { pagination.AssetType = 'R' }

        if (activeAssetMenu != 'Bundle') {
            directoryServices.MainAssetListing({ ...pagination }).then((res) => {

                categoryList = []
                setCategoryList(categoryList)
                for (var i = 0; i < res.length; i++) {

                    if (res[i].categoryName != ' ' && (categoryList.length < res.length - 1)) {
                        categoryList.push(res[i].categoryName)
                        setCategoryList(categoryList)
                    }
                }
                setdirectoryFullListing(res)
                settotalRow(res[0] ? res[0].totalRows : 0)

            })
        } else if (activeAssetMenu == 'Bundle') {
            directoryServices.BundleListing(bundlePayload).then((res) => {
                setdirectoryFullListing(res)
                settotalRow(res[0] ? res[0].totalRows : 0)
            })

        }

        setperCategory(false);
    }

    const SearchForHandle = (e: any, sts: any) => {

        console.log()

/**/
        if ((sts == true)) {
            var ARRAY_NEW :any = [];
            var payload = { ...e, ...pagination };

            if(e.searchFor){
                Object.values(e.searchFor).forEach(function (key) {
                    ARRAY_NEW.push(key)
                })
            }

            payload.searchFor = ARRAY_NEW;
            console.log(payload);

            if (activeAssetMenu != 'Bundle') {
                directoryServices.MainAssetListing(payload).then((res) => {
                    console.log(res)
                    res.map((values: any) => {

                        if (values.categoryName != ' ' && (categoryList.length < res.length - 1)) {
                            categoryList.push(values.categoryName)
                            setCategoryList(categoryList)
                        }
                    })
                    setdirectoryFullListing(res)
                    settotalRow(res[0] ? res[0].totalRows : 0)

                })
            }

            if (activeAssetMenu == 'Bundle') {
                directoryServices.BundleListing(payload).then((res) => {
                    //console.log(res)
                    setdirectoryFullListing(res)
                    settotalRow(res[0] ? res[0].totalRows : 0)

                })
            }

        }
        setperCategory(false);
    }

    const viewAll = (e: any) => {
        // console.log(e)
        if (viewAllList == false) {
            directoryServices.MainAssetListingAll({ ...{ "categoryName": e.categoryName }, ...pagination }).then((res) => {
                setdirectoryViewAll(res)
                //setSubtotalRow (res[0]?res[0].totalRows : 0)
            })
        }
    }

    const viewCategory = (e: any) => {
        console.log(e)

        pagination.categoryName = e;
        pagination.display = 'Category';
        console.log({ ...pagination })
        // setperCategory(true)
        directoryServices.MainAssetListingAll({ ...pagination }).then((res) => {
            setdirectoryFullListing(res)
            settotalRow(res[0] ? res[0].totalRows : 0)

        })


    }

    var creatorAssetPayLoad = {
        assetType: "T",
        categoryName: "",
        AssetImprint: selectedDetail.companyName,
        display: "Category",
        pageNumber: 1,
        pageSize: 10,
    }

    var bundlePayLoad = {
        pageNumber: 1,
        pageSize: 10,
        bundleImprint: selectedDetail.companyName
    }

    let [creatorAssetList, setcreatorAssetList] = useState<any>([]);
    let [creatorAssetCategory, setcreatorAssetCategory] = useState<any>([]);
    let [creatorAssettotalRow, setcreatorAssettotalRow] = useState(0);

    var SubclassFicationPayload = {
        area: "Imprints",
        referenceCode: "",
        text: "",
        pageNumber: 1,
        pagesize: 10
    }

    let [associatedSubclassification, setassociatedSubclassification] = useState<any>([]);
    let [associatedclassification, setassociatedclassification] = useState<any>([]);

    const creatorAsset = (e: any) => {

        console.log(selectedDetail)

        if (e != "All") {
            creatorAssetPayLoad.categoryName = e;
        } else { creatorAssetPayLoad.categoryName = ""; }

        //console.log(detailSubTab)
        if (detailSubTab == 1) {
            creatorAssetPayLoad.assetType = 'T'; subListing("song");
        } else if (detailSubTab == 2) {
            creatorAssetPayLoad.assetType = "S"; subListing("track");
        } else if (detailSubTab == 3) { creatorAssetPayLoad.assetType = "R"; subListing("track"); }

        console.log(selectedDetail)
        console.log(detailSubTab)
        setselectedDetail(selectedDetail)

        creatorAssetPayLoad.AssetImprint = selectedDetail.assetCode;
        bundlePayLoad.bundleImprint = selectedDetail.assetCode;
        relatedAssetPayLoad.CreatorCode = selectedDetail.assetCode;

        if (detailSubTab < 4 && (activeAssetMenu != 'Bundle')) {
            directoryServices.MainAssetListing(creatorAssetPayLoad).then((res) => {
                setcreatorAssetList(res)
                setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0)
                for (var $i = 0; $i < res.length; $i++) {

                    if (res[$i].categoryName != ' ' && e == "All" && (creatorAssetCategory.length < res[$i].length - 1)) {
                        //console.log("enter " + res[$i].categoryName)
                        creatorAssetCategory.push(res[$i].categoryName)
                        setcreatorAssetCategory(creatorAssetCategory)
                        //console.log(creatorAssetCategory)
                    }
                }
            })
        } else if (detailSubTab == 4 && (activeAssetMenu != 'Bundle')) {
            directoryServices.BundleListing(bundlePayLoad).then((res) => {
                setcreatorAssetList(res)
                //console.log(res)
                setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0)
            })
        }

        if ((activeAssetMenu != 'Bundle')) {

            SubclassFicationPayload.referenceCode = selectedDetail.assetCode;
            directoryServices.tagListing(SubclassFicationPayload).then((res) => {
                setassociatedSubclassification(res)
            })

            directoryServices.musicDirectoryCategoryListing({ code: selectedDetail.assetCode, CategoryType: activeAssetMenu }).then((res) => {
                setassociatedclassification(res)
            })
        }

        if ((activeAssetMenu == 'Bundle')) {
            subListing("track");
        }


    }


    var relatedAssetPayLoad = {
        CreatorCode: selectedDetail.companyName,
        type: "Creators",
        pageNumber: 1,
        pageSize: 10
    }

    let [creatorRelatedArtist, setcreatorRelatedArtist] = useState<any>([]);
    const relatedArtistListing = () => {
        directoryServices.creatorsListingAll(relatedAssetPayLoad).then((res) => {
            setcreatorRelatedArtist(res)
            console.log(res)
            setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0)
        })
    }

    let [dialogueAddOpenView, setdialogueAddOpenView] = useState(false)
    const onAddClose = (e: any) => {

        if (activeAssetMenu == 'Bundle') {
            console.log(e)
            setselectedDetail({ ...e })
            console.log(selectedDetail)
        }
        //console.log(e)
        setdialogueAddOpenView(false)
    }

    const onChangeMenu = (e: any) => {
        setActiveMenu(e)
        param.onChangeMenu(e)
    }

    let [activeListing, setActiveListing] = useState("")
    var ListingForTrack = {
        display: "song",
        iSRC: "11232304601"
    }
    var ListingForSong = {
        display: "song",
        songRef: "11232304601"
    }

    var ListingForRelease = {
        display: "song",
        catlogNumber: "11232304601"
    }
    const [assetLinkListing, setAssetLinkListing] = useState<any>([]);
    const [assetLinkRow, setAssetLinkRow] = useState(0);
    const subListing = (e: any) => {
        activeListing = e;
        setActiveListing(activeListing)
        var param = {};

        if (activeAssetMenu == 'Tracks') {
            ListingForTrack.display = activeListing;
            ListingForTrack.iSRC = selectedDetail.assetCode;
            param = ListingForTrack;
        }

        if (activeAssetMenu == 'Songs') {
            ListingForSong.display = activeListing;
            ListingForSong.songRef = selectedDetail.assetCode;
            param = ListingForSong;
        }

        if (activeAssetMenu == 'Releases') {
            ListingForRelease.display = activeListing;
            ListingForRelease.catlogNumber = selectedDetail.assetCode;
            param = ListingForRelease;
        }

        if ((activeAssetMenu != 'Bundle')) {
            directoryServices.LinkAssetListing(param).then((res) => {
                setAssetLinkListing(res)
                setAssetLinkRow(res[0] ? res[0].totalRows : 0)
            })
        }

        if ((activeAssetMenu == 'Bundle')) {
            var type = ''
            if (e == 'track') {
                type = 'T';
            } else if (e == 'song') {
                type = "S";
            } else if (e == 'release') { type = "R"; }
            directoryServices.BundleAssetListing({ bundleCode: selectedDetail.bundleCode, assetType: type }).then((res) => {
                setAssetLinkListing(res)
                setAssetLinkRow(res[0] ? res[0].totalRows : 0)
            })
        }
        ;

    }

    const [CloseUploaderOpen, setCloseUploaderOpen] = useState(false)
    const CloseUploader = (e: any) => {
        setCloseUploaderOpen(false)
    }


    return (

        <div className="landing ws-12">

            <div className='ws-12 bgWhite hide'>
                <Header Title={"Directory"} />
            </div>

            <div className="ws-12">

                <div className="box-header-main">
                    <h2>Music Directories</h2>
                </div>

                <div className="ws-2 box mtxl pm">
                    <ul className="main-menu">
                        <a className="menu-header">Music Directories</a>
                        <ul>
                            {
                                directoryServices.directoryMenu?.map((value: any) => {
                                    return (
                                        <>
                                            {(value.route != 'accounts') && <li key={value.title} className={activeMenu == value.title ? "active" : ""}>
                                                <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                            </li>}
                                        </>
                                    )
                                })
                            }
                        </ul>
                        <a className="menu-header">Data Directories</a>

                        <ul>
                            {
                                directoryServices.directoryMenu?.map((value: any) => {
                                    return (
                                        <>
                                            {(value.route == 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                            </li>}
                                        </>
                                    )
                                })
                            }

                        </ul>
                    </ul>

                    <div className="main-menu-span m0 box ws-12 fullHeight hide">
                        <div className="sidebar-nav ws-12">
                            <ul className="nav nav-stacked main-menu m0 ws-12" id="mainMenu">
                                <li key="MusicDirectories" className="mtl ws-12 pull-left p0" style={{ background: "none", border: "none" }}>
                                    <a className="nav-header full-width text-bold text-average "><i className="fa fa-music"></i> Music Directories</a>

                                    <ul className="nav nav-tabs full-width">
                                        {
                                            directoryServices.directoryMenu?.map((value: any) => {
                                                return (
                                                    <>
                                                        {(value.route != 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                            <a className={"ws-12 "} href={"#" + value.route} onClick={() => onChangeMenu(value.title)}> {value.title} </a>
                                                        </li>}
                                                    </>
                                                )
                                            })
                                        }

                                    </ul>
                                </li>

                                <li key="DataDirectories" className="mtl ws-12 pull-left p0" style={{ background: "none", border: "none" }}>
                                    <a className="nav-header full-width text-bold text-average "><i className="fa fa-music"></i> Data Directories</a>

                                    <ul className="nav nav-tabs full-width">
                                        {
                                            directoryServices.directoryMenu?.map((value: any) => {
                                                return (
                                                    <>
                                                        {(value.route == 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                            <a className={"ws-12 "} href={"#" + value.route} onClick={() => onChangeMenu(value.title)}> {value.title} </a>
                                                        </li>}
                                                    </>
                                                )
                                            })
                                        }

                                    </ul>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div className="ws-10">
                    {(detailView == false) && <><div className="pLarge">

                        <div className="ws-9">
                            <ul className="nav nav-tabs p0 m0">
                                <li key='Tracks' className={activeAssetMenu == 'Tracks' ? "active" : ""} onClick={() => { activeAssetMenu = ("Tracks"); LandingListing(); }}>Tracks</li>
                                <li key='Songs' className={activeAssetMenu == 'Songs' ? "active" : ""} onClick={() => { activeAssetMenu = ("Songs"); LandingListing() }}>Songs</li>
                                <li key='Releases' className={activeAssetMenu == 'Releases' ? "active" : ""} onClick={() => { activeAssetMenu = ("Releases"); LandingListing() }}>Releases</li>
                                <li key='Bundle' className={activeAssetMenu == 'Bundle' ? "active" : ""} onClick={() => { activeAssetMenu = ("Bundle"); LandingListing() }}>Bundle</li>
                            </ul>
                        </div>

                        {(activeAssetMenu != 'Bundle') && <div className="ws-9">
                            <ul className="nav nav-tabs p0 m0 green-nav-tabs">
                                <li key='All' className={activeCategory == 'All' ? "active " : ""}
                                    onClick={() => { setActiveCategory('All'); setperCategory(false); LandingListing(); }}>All</li>
                                {
                                    categoryList?.map((list: any) => {
                                        return (
                                            <li key={list} className={activeCategory == list ? "active " : ""}
                                                onClick={() => { setActiveCategory(list); setperCategory(true); viewCategory(list) }}>{list}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>}

                        <div className="ws-3">
                            <div className="verticalMediumWide hover-box">
                                <ul className="hover-block textboxDropdownList box pLarge defaultColor rightReset ws-2 mr">
                                    {(activeAssetMenu == 'Tracks') && <><li key='Add new Track' className="ws-12 pbs">
                                        <a onClick={() => { setuploader(7); setdialogueAddOpenView(true); setselectedDetail([]) }}>Add new Track</a>
                                    </li>

                                        <li key='Upload Track' className="ws-12 pbs">
                                            <a onClick={() => { setuploader(1); setCloseUploaderOpen(true) }}>Upload Track</a>
                                        </li></>}

                                    {(activeAssetMenu == 'Songs') && <><li key='Add new Song' className="ws-12 pbs">
                                        <a onClick={() => { setuploader(8); setdialogueAddOpenView(true); setselectedDetail([]) }}>Add New Song</a>
                                    </li>
                                        <li key='Upload Track' className="ws-12 pbs">
                                            <a onClick={() => { setuploader(11); setCloseUploaderOpen(true) }}>Upload Song</a>
                                        </li></>
                                    }

                                    {(activeAssetMenu == 'Releases') && <><li key='Add Release' className="ws-12 pbs">
                                        <a onClick={() => { setuploader(9); setdialogueAddOpenView(true); setselectedDetail([]) }}>Add Release</a>
                                    </li>
                                        <li key='Upload Track' className="ws-12 pbs">
                                            <a onClick={() => { setuploader(2); setCloseUploaderOpen(true) }}>Upload Release</a>
                                        </li>
                                        <li key='Upload Release/Track' className="ws-12 pbs">
                                            <a onClick={() => { setuploader(3); setCloseUploaderOpen(true) }}>Release/TrackUpload</a>
                                        </li>
                                        <li key='Upload Dealer price' className="ws-12 pbs">
                                            <a onClick={() => { setuploader(4); setCloseUploaderOpen(true) }}>Upload Dealer price</a>
                                        </li></>}
                                    {(activeAssetMenu == 'Bundle') && <> <li key='Upload Bundle' className="ws-12 pbs">
                                        <a onClick={() => { setuploader(5); setCloseUploaderOpen(true) }}>Upload Bundle</a>
                                    </li>
                                        <li key='Add New Bundle' className="ws-12 pbs">
                                            <a onClick={() => { setuploader(6); setdialogueAddOpenView(true); setselectedDetail([]) }}>Add New Bundle</a>
                                        </li></>}

                                </ul>
                                <button className="btn-info pull-right">
                                    <i className="icon-grid m0 p0"></i>
                                </button>
                            </div>

                        </div>

                        <hr />
                        <div className="ws-12 ">
                            <CommonSearch SearchForHandle={SearchForHandle} type="contactGrid" title="Imprints" width="ws-10 pull-left" />
                        </div>

                        <div className="ws-12">
                            <div className="box-content">
                                <h2>Showing <span className="info">{totalRow}</span> results</h2>
                            </div>
                        </div>

                    </div>

                        {(activeAssetMenu != 'Bundle') && <div className="ws-12 landing pLarge">

                            {(!perCategory && directoryFullListing.length > 0) && <>
                                {
                                    directoryFullListing?.map((valueObj: any) => {
                                        return (
                                            <>
                                                {(valueObj && valueObj.categoryName != " ") && <h2 className="ws-12">
                                                    <span className="text-bold pt">{valueObj.categoryName}</span>
                                                    <span className="info">({valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0})</span>
                                                    {((valueObj.categoryDetails && valueObj.categoryDetails[0].totalSubRows) > 5) && <a className="btn-primary pull-right" onClick={() => { viewAll(valueObj); setviewAllList(!viewAllList) }}> View All </a>}
                                                </h2>}

                                                {(valueObj && valueObj.categoryName != " ") && <div>
                                                    {
                                                        valueObj.categoryDetails?.map((value: any, index: any) => {

                                                            if (!(directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && viewAllList)) {
                                                                return (

                                                                    <>{(index < 5) && <div className="creatorImg pm pull-left">

                                                                        <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                            <div className="coverPicture">

                                                                                <img className="ws-12" src={value.labelImage} />

                                                                            </div>
                                                                            <div className="bottomOverlayLabel">
                                                                                <label className="control-label ws-12"> {CommonUtilities.longCharacter(value.assetContactName, 15)}</label>
                                                                                <label className="control-label ws-12"> {CommonUtilities.longCharacter(value.name, 15)}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>}</>

                                                                )
                                                            }

                                                            if ((directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && index < 1 && viewAllList)) {

                                                                return (<>
                                                                    {directoryViewAll.map((values) => {
                                                                        return (
                                                                            <div className="creatorImg pm pull-left">

                                                                                <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                                    <div className="coverPicture">

                                                                                        <img className="ws-12" src={values.labelImage} />

                                                                                    </div>
                                                                                    <div className="bottomOverlayLabel">
                                                                                        <label className="control-label ws-12"> {value.assetContactName}</label>
                                                                                        <label className="control-label ws-12"> {value.name} </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>)
                                                                    })}</>)
                                                            }
                                                        })
                                                    }
                                                </div>}
                                            </>
                                        )
                                    })
                                }

                                {
                                    directoryFullListing?.map((valueObj: any) => {
                                        return (
                                            <>
                                                {(valueObj.categoryName == " ") && <h2 className="ws-12">
                                                    <span className="text-bold pt"> Uncategorised</span>
                                                    <span className="info">({valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0})</span>
                                                    {((valueObj.categoryDetails && valueObj.categoryDetails[0].totalSubRows) > 5) && <a className="btn-primary pull-right" onClick={() => { viewAll(valueObj); setviewAllList(!viewAllList) }}> View All </a>}
                                                </h2>}

                                                {(valueObj.categoryName == " ") && <div >
                                                    {
                                                        valueObj.categoryDetails.map((value: any, index: any) => {
                                                            if (!(directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && viewAllList)) {
                                                                return (

                                                                    <>{(index < 5) && <div className="creatorImg pm pull-left">

                                                                        <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                            <div className="coverPicture">

                                                                                <img className="ws-12" src={value.labelImage} />

                                                                            </div>
                                                                            <div className="bottomOverlayLabel">
                                                                                <label className="control-label ws-12"> {value.assetContactName}</label>
                                                                                <label className="control-label ws-12"> {value.name} </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>}</>

                                                                )
                                                            }

                                                            if ((directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && index < 1 && viewAllList)) {

                                                                return (<>
                                                                    {directoryViewAll.map((values) => {
                                                                        return (
                                                                            <div className="creatorImg pm pull-left">

                                                                                <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                                    <div className="coverPicture">

                                                                                        <img className="ws-12" src={values.labelImage} />

                                                                                    </div>
                                                                                    <div className="bottomOverlayLabel">
                                                                                        <label className="control-label ws-12"> {value.assetContactName}</label>
                                                                                        <label className="control-label ws-12"> {value.name} </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>)
                                                                    })}</>)
                                                            }

                                                        })
                                                    }
                                                </div>}
                                            </>
                                        )
                                    })
                                }</>}


                            {(perCategory && directoryFullListing.length > 0) && <>

                                {directoryFullListing?.map((values: any) => {
                                    return (
                                        <div className="creatorImg pm pull-left">

                                            <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (values);; creatorAsset("All") }}>

                                                <div className="coverPicture">

                                                    <img className="ws-12" src={values.labelImage} />

                                                </div>
                                                <div className="bottomOverlayLabel">
                                                    <label className="control-label ws-12"> {CommonUtilities.longCharacter(values.assetContactName, 15)}</label>
                                                    <label className="control-label ws-12"> {CommonUtilities.longCharacter(values.name, 15)}</label>
                                                </div>
                                            </div>
                                        </div>)
                                })

                                }
                            </>}

                        </div>}

                        {(activeAssetMenu == 'Bundle') && <div className="ws-12 landing pLarge">
                            {
                                directoryFullListing?.map((values: any) => {
                                    return (
                                        <div className="creatorImg pm pull-left">

                                            <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (values);setselectedDetail(selectedDetail); creatorAsset("All") }}>

                                                <div className="coverPicture">

                                                    <img className="ws-12" src={values.bundleImage} />

                                                </div>
                                                <div className="bottomOverlayLabel">
                                                    <label className="control-label ws-12"> {values.bundleTitle}</label>
                                                    <label className="control-label ws-12"> {values.bundleCode} </label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>}

                    </>}

                    {(detailView == true) && <>
                        <div className="ws-12 pLarge">
                            <button className="btn-primary btn-sm mrl" onClick={() => setdetailView(false)}>
                                <i className="icon-angle-left"></i>
                            </button>

                            <div className="mt">
                                <span className="text-bold pt" style={{ textDecoration: "underline" }}> Assets directory</span>
                                {(activeAssetMenu != 'Bundle') && <span className="text-bold pt"> {">"} {selectedDetail.name}</span>}
                                {(activeAssetMenu == 'Bundle') && <span className="text-bold pt"> {">"} {selectedDetail.bundleTitle}</span>}</div>


                        </div>
                        <hr className="mtxl" />

                        <div className="pl ws-12 landing pt">
                            {<div className="box">
                                <div className="ws-2 pll box landing ptl prl">

                                    {(activeAssetMenu != 'Bundle') && <div className="ws-11 text-sl text-bold mbs forceSingleLine">{selectedDetail.name}</div>}
                                    {(activeAssetMenu == 'Bundle') && <div>
                                        <div className="ws-6 text-sl text-bold mbs forceSingleLine">{selectedDetail.bundleTitle}</div>
                                        <div className="verticalMediumWide hover-box">
                                            <ul className="hover-block textboxDropdownList box pLarge defaultColor rightReset ws-3">
                                                <li className="ws-12 pbs">
                                                    <a onClick={() => { setuploader(6); setdialogueAddOpenView(true);setselectedDetail(selectedDetail) }}>Edit Bundle</a>
                                                </li>
                                                <hr className="mt" />
                                                <li className="ws-12 danger">
                                                    <a style={{ color: 'red' }}>Delete Bundle</a>
                                                </li>
                                            </ul>
                                            <button className="btn-xs btn-info pull-right">
                                                <i className="icon-compose"></i>
                                            </button>
                                        </div>
                                    </div>

                                    }

                                    {(activeAssetMenu != 'Bundle') && <><hr className="ws-11" />
                                        <div className="ws-11 mbs forceSingleLine">
                                            <div>{selectedDetail.assetContactName}</div>
                                        </div></>}

                                    <hr className="ws-11 mbs" />
                                    <div className="ws-11 mbs mt">
                                        {(activeAssetMenu != 'Bundle') && <span className="btn-xs btn-grey text-uppercase m0 ws-6 text-center mr">{activeAssetMenu} code</span>}
                                        {(activeAssetMenu == 'Bundle') && <span className="btn-xs btn-grey text-uppercase m0 ws-6 text-center mr">Bundle code</span>}

                                        {(activeAssetMenu != 'Bundle') && <span className="ws-6 pt">{selectedDetail.assetCode}</span>}
                                        {(activeAssetMenu == 'Bundle') && <span className="ws-6 pt">{selectedDetail.bundleCode}</span>}

                                    </div>

                                    {(activeAssetMenu != 'Bundle') && <><hr className="ws-11" />
                                        <div className="ws-11 mbs">
                                            <span className="pull-left text-bold mr">Classification: </span>
                                            {
                                                associatedclassification?.map((values: any) => {
                                                    return (
                                                        <span className="pull-left pr">{values.categoryName}</span>
                                                    )
                                                })
                                            }

                                        </div></>}

                                    {(activeAssetMenu != 'Bundle') && <><hr className="ws-11" />
                                        <div className="ws-11 mbs">
                                            <span className="pull-left text-bold mr">Sub classification: </span>
                                            {
                                                associatedSubclassification?.map((values: any) => {
                                                    return (
                                                        <span className="pull-left pr">{values.categoryName}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                        <hr className="ws-11" /></>}
                                    <div className="ws-12 mtl pr">
                                        <div className="box shadow mb">
                                            <div className="coverPicture" style={{ height: "200px" }}>
                                                {(activeAssetMenu != 'Bundle') && <img className="ws-12" src={selectedDetail.assetImage} />}
                                                {(activeAssetMenu == 'Bundle') && <img className="ws-12" src={selectedDetail.bundleImage} />}
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="ws-10">
                                    <div className="pLarge ptl ws-12">

                                        <div >
                                            <ul className="ws-11 nav nav-pills p0 m0">
                                                {!(detailSubTab == 1 && (activeAssetMenu != 'Bundle')) && <li onClick={() => { subListing("track") }} className={activeListing == "track" ? "active" : ""}>
                                                    <a>Track Listing </a> </li>}
                                                {!(detailSubTab == 2 && (activeAssetMenu != 'Bundle')) && <li onClick={() => { subListing("song") }} className={activeListing == "song" ? "active" : ""}>
                                                    <a>Song Listing</a> </li>}
                                                {!(detailSubTab == 3 && (activeAssetMenu != 'Bundle')) && <li onClick={() => { subListing("release") }} className={activeListing == "release" ? "active" : ""}>
                                                    <a>Release Listing</a> </li>}

                                                <li><a>Analytics</a> </li>
                                                <hr className="ws-12  m0 p0" />
                                            </ul>

                                            <div className="verticalMediumWide hover-box">
                                                <ul className="hover-block textboxDropdownList box pLarge defaultColor rightReset ws-3">
                                                    {/*                                                         {(activeAssetMenu != 'Bundle') && <li className="ws-12 pbs">
                                                            <a>Edit Profile</a>
                                                        </li>} */}

                                                    {(activeAssetMenu == 'Songs') && <li className="ws-12 pbs">
                                                        <a onClick={() => { setuploader(8); setdialogueAddOpenView(true) }}>Edit Profile</a>
                                                    </li>}

                                                    {(activeAssetMenu == 'Tracks') && <li className="ws-12 pbs">
                                                        <a onClick={() => { setuploader(7); setdialogueAddOpenView(true) }}>Edit Profile</a>
                                                    </li>}

                                                    {(activeAssetMenu == 'Releases') && <li className="ws-12 pbs">
                                                        <a onClick={() => { setuploader(9); setdialogueAddOpenView(true) }}>Edit Profile</a>
                                                    </li>}


                                                    {(activeAssetMenu == 'Bundle') && <li className="ws-12 pbs">
                                                        <a onClick={() => { setuploader(10); setdialogueAddOpenView(true) }}>Add Asset</a>
                                                    </li>}
                                                </ul>
                                                <button className="btn btn-info pull-right">
                                                    <i className="icon-grid m0 p0"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="ws-11" >

                                            {(assetLinkRow > 0) && <>
                                                <div className="ws-12">
                                                    {activeListing == "track" && <h1 className="ws-6 ">Track title</h1>}
                                                    {activeListing == "song" && <h1 className="ws-6 ">Song title</h1>}
                                                    {activeListing == "release" && <h1 className="ws-6 ">Release title</h1>}
                                                    {<h1 className="ws-6">Artist</h1>}
                                                    <hr className="ws-12  m0 p0" />
                                                </div>

                                                {assetLinkListing?.map((values: any) => {

                                                    return (
                                                        <div className="ws-12 pt">
                                                            <span className="ws-1 pt">
                                                                <i className="icon-cd"></i>
                                                            </span>
                                                            <span className="ws-5 text-bold pt">{values.name}</span>
                                                            <span className="ws-4 text-bold pt">{values.assetContactName}</span>
                                                            {(activeAssetMenu == 'Bundle') && <div className="ws-2 pull-right">
                                                                <span className="btn-success btn btn-xs"><i className="icon-edit1"></i></span>
                                                                <span className="btn-danger btn btn-xs"><i className="icon-trashcan"></i></span>
                                                            </div>}
                                                            <hr className="ws-12" />
                                                        </div>
                                                    )
                                                })}
                                            </>}

                                            {(assetLinkRow == 0) && <div className="ws-12 mtl">
                                                <span className="alert alert-success ws-12 text-center">No data Available</span>
                                            </div>}
                                        </div>

                                    </div>
                                </div>

                            </div>}

                        </div>
                    </>}
                </div>

            </div>

            {
                (() => {

                    if (uploader == 1) {
                        return <AssetUploader open={CloseUploaderOpen} type={"Tracks"} CloseUploader={CloseUploader} />
                    }

                    if (uploader == 2) {
                        return <AssetUploader open={CloseUploaderOpen} type={"Releases"} CloseUploader={CloseUploader} />
                    }

                    if (uploader == 3) {
                        return <AssetUploader open={CloseUploaderOpen} type={"ReleasesTracks"} CloseUploader={CloseUploader} />
                    }

                    if (uploader == 4) {
                        return <AssetUploader open={CloseUploaderOpen} type={"DeaperPrice"} CloseUploader={CloseUploader} />
                    }

                    if (uploader == 5) {
                        return <AssetUploader open={CloseUploaderOpen} type={"Bundle"} CloseUploader={CloseUploader} />
                    }

                    if (uploader == 6) {
                        return <BundleSlider open={dialogueAddOpenView} editData={selectedDetail} onAddClose={onAddClose} CloseUploader={CloseUploader} />
                    }

                    if (uploader == 7) {
                        return <TrackSlider open={dialogueAddOpenView} editData={selectedDetail} onAddClose={onAddClose} CloseUploader={CloseUploader} />
                    }

                    if (uploader == 8) {
                        return <SongSlider open={dialogueAddOpenView} editData={selectedDetail} onAddClose={onAddClose} CloseUploader={CloseUploader} />
                    }

                    if (uploader == 9) {
                        return <ReleaseSlider open={dialogueAddOpenView} editData={selectedDetail} onAddClose={onAddClose} CloseUploader={CloseUploader} />
                    }


                    if (uploader == 10) {
                        return <LinkAssetBundle open={dialogueAddOpenView} editData={selectedDetail} onAddClose={onAddClose} CloseUploader={CloseUploader} />
                    }

                    if (uploader == 11) {
                        return <AssetUploader open={CloseUploaderOpen} type={"Songs"} CloseUploader={CloseUploader} />
                    }

                })()
            }
        </div>
    )
}   