import React, { Suspense, useState, useEffect, useRef } from "react";


import { Modal } from 'react-responsive-modal';




export default function DeleteDialogueBox(param:any) {
    const [openFirst, setOpenFirst] = React.useState(false);

    const returnValue = (e : any) => {
        param.onChange(e)
        setOpenFirst(false) 
    }
    return(
<>

    <Modal open={param.param} onClose={() => returnValue(2)} center>
       
        <div className="box  box-header-fix" >

            <div className="box-header well">
                <h2><i className="fa fa-question-circle"></i> Confirm?</h2>
            </div>

            <div className="box-content test"><span>Are you sure you want to {param.message} ?</span></div>

            <div className="box-footer">

                <button className="btn-danger" onClick={() => returnValue(1)} > Yes</button>
                <button className="btn-primary" onClick={() => returnValue(2)} > No</button>
            </div>

        </div>
    </Modal></>
    )
}