import React, { useEffect } from 'react';
import Navbar from './navbar';
import korrectLogo from "../../assets/images/korrectLogo.png";

export default function Header(param: any) {

    return (
        <div className='ws-12'>
            <div className='ws-12' >
                <a className='mtl mll pull-left' href='../../'><img src={korrectLogo} height='49px' /></a>
                <img className='mtl pull-left' src={global.portalLogo ? global.portalLogo : '/assets/images/logo.png'} height='49px' />

                <Navbar />

            </div>

            {(param.Title) && <div className='box'>
                <div className='box-header noBorderRadius bg-blue text-bold'>
                    <h2 className=' bg-blue'>{param.Title}</h2>
                </div>
            </div>}
        </div>
    )


}