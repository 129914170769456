import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import commonServices from '../Common/commonServices';
import Pagination from 'components/Header/Pagination';
import { Label } from './label';
import LabelServices from './LabelServices';

export function ImprintFom (param:any) {

    let [showTab , setShowTab] = useState(0);
    const toggleDrawer = () => {setIsOpen(!isOpen)}
    const [isOpen, setIsOpen] = React.useState(false); 
    const ArrayHeader = ["Type","Business Info", "Tax info" , "Company Info" , "Accounting Info"];
    const [saveAlert , setsaveAlert] = useState(false);
    const [totalRow, inittotalRow] = useState(0);
    const [saveAlertB , setsaveAlertB] = useState(false);
    let [valid , setvalid] = useState(false);
    const [saveAlertT , setsaveAlertT] = useState(false);
    const [saveAlertC , setsaveAlertC] = useState(false);
    const [saveAlertA , setsaveAlertA] = useState(false);
    let [SearchContact , setSearchContact] = useState("");
    let [telephoneBtn , settelephoneBtn] = useState({tel1:false, tel2:false , tel3:false});
    let [ExistContact, initExistContact] = useState<any[]>([]);
    let [BusinessContact, initBusinessContact] = useState<any>([]);
    let [imprintType, initImprintType] = useState<any>([]);
    let [imprintAccounting, initImprintAccounting] = useState<any>([]);
    let [imprintTaxInfo, initImprintTaxInfo] = useState<any>([]);
    let [imprintCompanyInfo, initImprintCompanyInfo] = useState<any>([]);

    const changePage =(e:any) => {
        console.log(imprintType)        
        console.log(imprintTaxInfo)
        console.log(BusinessContact)
        console.log(imprintAccounting)
        console.log(imprintCompanyInfo)

        if(e == "prev"){
            showTab = showTab -1;
            setShowTab(showTab)
        }else if(e == "next"){           
            
            initImprintType({...imprintType , ["imprintId"]:0  ,["contractRefNum"] : param.data.contractRefNum})            
            if(FormValidation() == true){
               // LabelServices.kontactImprintTypeSave(imprintType);*/
                showTab = showTab +1;
                setShowTab(showTab)  
                
                if(showTab == 1 ){
                    commonServices.getContactDetails({"pageNumber": 1, "pageSize": 10}).then( (data)=>{
                        initExistContact(data);
                        inittotalRow(data[0].totalRows?data[0].totalRows : 0)
                    })
                }                     
            }
        }else{
            setShowTab(e);
        }

        if(showTab == 1 ){
            commonServices.getContactDetails({"pageNumber": 1, "pageSize": 10}).then( (data)=>{
                initExistContact(data);
                inittotalRow(data[0].totalRows?data[0].totalRows : 0)
            })
        }

    }

    var data ={ 
        "pageSize" : 10 ,
        "pageNumber" : 1
    }
    
    const handleNameOnChangeImprint = (n: any) => {
        data.pageNumber = n.pageNumber;
        data.pageSize = n.pageSize;
        commonServices.getContactDetails({"pageNumber": data.pageNumber, "pageSize": data.pageSize}).then( (data)=>{
            initExistContact(data);
            inittotalRow(data[0].totalRows?data[0].totalRows : 0)
        }) 
    }

    const telephone_btn = (n :any) => {
        var name = n.target.name
        if(n.target.name == "tel1"){  settelephoneBtn(existingValues  => ({ ...existingValues,tel1:n.target.checked })); }
        if(n.target.name == "tel2"){  settelephoneBtn(existingValues  => ({ ...existingValues,tel2:n.target.checked })); }
        if(n.target.name == "tel3"){  settelephoneBtn(existingValues  => ({ ...existingValues,tel3:n.target.checked })); }        
    }

    const setBusinessContact = (n :any ) => {

        BusinessContact = [];
        initBusinessContact(BusinessContact)
        settelephoneBtn(existingValues  => ({ ...existingValues,tel1:false ,tel2:false, tel3:false})); 
        BusinessContact = n;
        initBusinessContact(BusinessContact);        
        
        if(n.kontactsAddresses.telephone2){  settelephoneBtn(existingValues  => ({ ...existingValues,tel1:true })); }
        if(n.kontactsAddresses.telephone3){  settelephoneBtn(existingValues  => ({ ...existingValues,tel2:true })); }
        if(n.kontactsAddresses.telephone4){  settelephoneBtn(existingValues  => ({ ...existingValues,tel3:true })); }
    }

    const getSearchContact = (n :any) =>{
        SearchContact = n;
        setSearchContact(SearchContact) ;

        if(SearchContact.length > 3){
            commonServices.getContactDetails({"pageNumber": data.pageNumber, "pageSize": data.pageSize , "contact":SearchContact}).then( (data)=>{
                initExistContact(data);
                inittotalRow(data[0].totalRows?data[0].totalRows : 0)
            }) 
        }
    }

    const formHandler = (event  :any) => {

        let  field_name = event.target.name ;let  field_value = event.target.value ;
        if(field_name.includes(".")){
            const regex = new RegExp(`\\b.\\b`, 'i');
            const match = field_name.match(regex);
    
            var main_obj = field_name.slice(0,match.index);
            var sub_obj = field_name.slice((match.index+1),field_name.length);

            if(showTab == 0){ initImprintType({...imprintType , [main_obj]: { ...imprintType[main_obj],  [sub_obj]: field_value,  }}); } 
            if(showTab == 1){ initBusinessContact({...BusinessContact , [main_obj]: { ...BusinessContact[main_obj],  [sub_obj]: field_value,  }}); } 
            if(showTab == 2){ initImprintTaxInfo({...imprintTaxInfo , [main_obj]: { ...imprintTaxInfo[main_obj],  [sub_obj]: field_value,  }}); } 
            if(showTab == 3){ initImprintCompanyInfo({...imprintCompanyInfo , [main_obj]: { ...imprintCompanyInfo[main_obj],  [sub_obj]: field_value,  }}); } 
            if(showTab == 4){ initImprintAccounting({...imprintAccounting , [main_obj]: { ...imprintAccounting[main_obj],  [sub_obj]: field_value,  }}); } 
        }else{
            if(showTab == 0){ initImprintType({...imprintType , [field_name] :field_value  });}
            if(showTab == 1){ initBusinessContact({...BusinessContact , [field_name] :field_value });}
            if(showTab == 2){ initImprintTaxInfo({...imprintTaxInfo , [field_name] :field_value });}
            if(showTab == 3){ initImprintCompanyInfo({...imprintCompanyInfo , [field_name] :field_value });}
            if(showTab == 4){ initImprintAccounting({...imprintAccounting , [field_name] :field_value });}
        }
    }
    
    let validField = true;
    const FormValidation = () =>{

        const nameElement :any= document.getElementById("name");        
        const codeElement :any= document.getElementById("code");
        const lastNameElement :any= document.getElementById("lastName"); 
        const sequenceElement :any= document.getElementById("sequence");
        const firstNameElement :any= document.getElementById("firstName");        
        const vendorCodeElement :any= document.getElementById("vendorCode");
        const companyNameElement :any= document.getElementById("companyName");
        const imprintEmailElement :any= document.getElementById("imprintEmail"); 
        const imprintTypeElement :any= document.getElementById("imprintTypeName");  
        const federalTaxClassificationElement :any= document.getElementById("federalTaxClassification");
        
        /*nameElement.style.border = "1px solid #DEDEDE";    
        codeElement.style.border = "1px solid #DEDEDE"; 
        lastNameElement.style.border = "1px solid #DEDEDE"; 
        sequenceElement.style.border = "1px solid #DEDEDE"; 
        firstNameElement.style.border = "1px solid #DEDEDE";  
        vendorCodeElement.style.border = "1px solid #DEDEDE"; 
        companyNameElement.style.border = "1px solid #DEDEDE";
        imprintTypeElement.style.border = "1px solid #DEDEDE"; 
        imprintEmailElement.style.border = "1px solid #DEDEDE"; 
        federalTaxClassificationElement.style.border = "1px solid #DEDEDE"; */

        if(showTab == 0){
            if(!(imprintType.imprintType) ){ imprintTypeElement.style.border = "1px solid #e50e0e"; validField =false; setvalid(true)}
            else if(!!(imprintType.imprintType) && (imprintType.imprintType.length ==0)){imprintTypeElement.style.border = "1px solid #e50e0e"; validField =false; } 

        }if(showTab == 1){
            if(!(BusinessContact.firstName) ){ firstNameElement.style.border = "1px solid #e50e0e"; validField =false; setvalid(true)}
            else if(!!(BusinessContact.firstName) && (BusinessContact.firstName.length ==0)){firstNameElement.style.border = "1px solid #e50e0e"; validField =false; }

            if(!(BusinessContact.lastName) ){ lastNameElement.style.border = "1px solid #e50e0e"; validField =false; setvalid(true)}
            else if(!!(BusinessContact.lastName) && (BusinessContact.lastName.length ==0)){lastNameElement.style.border = "1px solid #e50e0e"; validField =false; }
            
            if( (BusinessContact.emailAdd1  &&  (BusinessContact.emailAdd1.includes("@gmail.com")) )  ){
                imprintEmailElement.style.border = "1px solid #e50e0e"; validField =false; setvalid(true);
            }

        }if(showTab == 2){
            if(!(imprintTaxInfo.name) ){ nameElement.style.border = "1px solid #e50e0e"; validField =false; setvalid(true)}
            else if(!!(imprintTaxInfo.name) && (imprintTaxInfo.name.length ==0)){nameElement.style.border = "1px solid #e50e0e"; validField =false; }

            if(!(imprintTaxInfo.federalTaxClassification) ){ federalTaxClassificationElement.style.border = "1px solid #e50e0e"; validField =false; setvalid(true);}
            else if(!!(imprintTaxInfo.federalTaxClassification) && (imprintTaxInfo.federalTaxClassification.length ==0)){federalTaxClassificationElement.style.border = "1px solid #e50e0e"; validField =false; }
            
        }if(showTab == 3){

            if(!(imprintCompanyInfo.companyName) ){ companyNameElement.style.border = "1px solid #e50e0e"; validField =false; setvalid(true);}
            else if(!!(imprintCompanyInfo.companyName) && (imprintCompanyInfo.companyName.length ==0)){nameElement.style.border = "1px solid #e50e0e"; validField =false; }

            if(!(imprintCompanyInfo.code) ){ codeElement.style.border = "1px solid #e50e0e"; validField =false; setvalid(true);}
            else if(!!(imprintCompanyInfo.code) && (imprintCompanyInfo.code.length ==0)){codeElement.style.border = "1px solid #e50e0e"; validField =false; } 
            
            if(!(imprintCompanyInfo.sequence) ){ sequenceElement.style.border = "1px solid #e50e0e"; validField =false; setvalid(true);}
            else if(!!(imprintCompanyInfo.sequence) && (imprintCompanyInfo.sequence.length ==0)){sequenceElement.style.border = "1px solid #e50e0e"; validField =false; } 
        }if(showTab == 4){
            if(!(imprintCompanyInfo.SAPCode) ){ vendorCodeElement.style.border = "1px solid #e50e0e"; validField =false; setvalid(true);}
            else if(!!(imprintCompanyInfo.SAPCode) && (imprintCompanyInfo.SAPCode.length ==0)){vendorCodeElement.style.border = "1px solid #e50e0e"; validField =false; } 
        }

        setTimeout(function(){
            setvalid(false)
        }, 1000);        

        return validField;
    }

    return (
        < > 
            <button type="button" className="btn-success" onClick={() => {setIsOpen(!isOpen)} }>Add</button>
            <Drawer 
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                className='box react-drawer landing '
                size={950}>
                
                <div className='ws-2 full-height wizardNavigation'>
                    <div className='pLarge'>
                        <table className='mtl wizard '>

                            {
                                ArrayHeader.map((post , index) => 
                                    {return (
                                        <tr className={showTab ==(index+1)?" pull-right ws-12  selected" :" pull-right ws-12 "}>
                                            <td className="pull-left">
                                                <span className="tabIndex zFront">{index +1}</span>
                                                    <i className="icon-filled-circle positionAbsolute"></i>
                                            </td>        
                                            <td className="p0">
                                                <span className={showTab ==(index)? "text-bold selected white mlxl" :"text-bold mlxl" }
                                                style={{fontWeight: "bold", fontSize: "13px"}}>{post}</span>
                                                {/* onClick={() => {showTab =index ; changePage(showTab);}}>{post}</span> */}
                                             </td>    
                                        </tr >
                                    );}    
                                        
                                )    
                            }                                

                        </table>
                    </div>
                </div>

                <div className='ws-10 landing '>
                    <div className='ws-12 hide'> <i className="icon-close pull-right ptl prl relative" onClick={() => {setIsOpen(!isOpen)} }/></div>
                    
                    {(showTab == 0) &&  <div>
                        <div className='box box-content'>
                            <div className="box-header">
                                <h2> Create Imprint Publisher/Record Label</h2>
                            </div>
                            {valid && <div className="alert alert-danger">
                                    There was a problem submitting your request, one or more fields are missing or incorrect.
                            </div>}

                            {saveAlert && <div className="alert alert-success" data-ng-show="saveAlert">Imprint details saved successfully.</div>}

                            <div>
                                <div className="form-format">
                                    <label className="control-label">Type*</label>
                                    <div className="label-entries">
                                        <select className="full-height" id='imprintTypeName' name="imprintType" value={(imprintType && imprintType.imprintType)?imprintType.imprintType:""} onChange={(e)=>formHandler(e)} >
                                            <option value="" >Choose Imprint Type</option>
                                            <option value="Formally Organised Corporation">Formally Organised Corporation</option>
                                            <option value="Formally Organised LLC">Formally Organised LLC</option>
                                            <option value="Individually Owned">Individually Owned</option>
                                            <option value="Partnership">Partnership</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-format">
                                    <label className="control-label" >Record Label/Publisher</label>
                                    <div className="label-entries">
                                        <select value={(imprintType && imprintType.recordLabel_Publisher)?imprintType.recordLabel_Publisher:""} id='recordLabel_Publisher'
                                            name="recordLabel_Publisher" onChange={(e)=>formHandler(e)}>
                                            <option value="" >Choose Label/Publisher</option>
                                            <option value="Imprint Record Label">Imprint Record Label</option>
                                            <option value="Imprint Publisher">Imprint Publisher</option></select>
                                    </div>
                                </div>

                                <div className="form-format hide">
                                    <label className="control-label" >Sequence</label>
                                    <div className="label-entries">
                                        <input type="number" ng-model="labelTypeDetails.sequence" name="sequence" className="text-right " placeholder="Enter Sequence"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>}

                    {(showTab == 1) && <div className='ws-12'>
                        <div className='ws-7 pr pt pl'>
                            <fieldset className="scheduler-border">

                                <legend className="scheduler-border mb"><h2 style={{color:"#47529d;"}} ><i className="fa fa-sticky-note-o"></i> Business Contact Name</h2></legend>
                                {saveAlertB && <div className="alert alert-success" data-ng-show="saveAlertB">Business Information saved
                                    successfully.
                                </div>}

                                {valid && <div className="alert alert-danger">
                                    There was a problem submitting your request, one or more fields are missing or incorrect.
                                </div>}

                                <div className="form-format ws-3">
                                    <label className="control-label text-left">FirstName*</label>
                                </div>

                                <div className="form-format ws-3">
                                    <label className="control-label text-left" > MiddleName</label>
                                </div>

                                <div className="form-format ws-3">
                                    <label className="control-label text-left">LastName*</label>
                                </div>

                                <div className="form-format ws-3">
                                    <label className="control-label text-left">Suffix</label>
                                </div>

                                <div className="form-format ws-3">
                                    <div className="label-entries">
                                        <input type="text" id="firstName" name="firstName" onChange={(e)=>formHandler(e)} value={Object.keys(BusinessContact).length?BusinessContact.firstName : ""} placeholder="Enter First Name" className="mss"/>
                                    </div>
                                </div>
                                <div className="form-format ws-3">
                                    <div className="label-entries">
                                        <input type="text" name="middleName"  className="mss" onChange={(e)=>formHandler(e)} placeholder="Enter Middle Name"/>
                                    </div>
                                </div>

                                <div className="form-format ws-3">
                                    <div className="label-entries">
                                        <input type="text" id="lastName" name="lastName" onChange={(e)=>formHandler(e)} value={Object.keys(BusinessContact).length?BusinessContact.lastName : ""} placeholder="Enter Last Name" className="mss " />
                                    </div>
                                </div>

                                <div className="form-format ws-3">
                                    <div className="label-entries">
                                        <select name="suffix" id="suffix" className="mss" onChange={(e)=>formHandler(e)} value={Object.keys(BusinessContact).length?BusinessContact.suffix : ""}>
                                            <option value="" >Choose Suffix</option>
                                            <option value="1">Jr</option>
                                            <option value="2">Sir</option>
                                            <option value="3">II</option>
                                            <option value="4">III</option>
                                            <option value="5">IV</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="ws-12 ">
                                    <div className="form-format ws-4">
                                        <label className="control-label text-left" >BusinessAddress</label>
                                    </div>
                                </div>

                                <div className="form-format ws-12">
                                    <div className="label-entries">
                                        <input type="text" className="mss " name="kontactsAddresses.addressLine1" placeholder="Enter Business Address" onChange={(e)=>formHandler(e)}
                                        value={Object.keys(BusinessContact).length?BusinessContact.kontactsAddresses? BusinessContact.kontactsAddresses.addressLine1: "" : ""}/>
                                    </div>
                                </div>
                                <div className="form-format ws-4">
                                    <label className="control-label text-left">Country</label>
                                </div>
                                <div className="form-format ws-4">
                                    <label className="control-label text-left"> City</label>
                                </div>

                                <div className="form-format ws-4">
                                    <label className="control-label text-left">ZipCode</label>
                                </div>

                                <div className="form-format ws-4">
                                    <div className="label-entries">
                                        <input type="text" className="mss" onChange={(e)=>formHandler(e)} name="kontactsAddresses.addressLine4" placeholder="Enter Country" value={BusinessContact.kontactsAddresses?
                                            BusinessContact.kontactsAddresses.addressLine4!= null? BusinessContact.kontactsAddresses.addressLine4: "" : ""}/>
                                    </div>
                                </div>
                                <div className="form-format ws-4">
                                    <div className="label-entries">
                                        <input type="text" name="kontactsAddresses.addressLine3" onChange={(e)=>formHandler(e)} className="mss" placeholder="Enter City" value={BusinessContact.kontactsAddresses?
                                            BusinessContact.kontactsAddresses.addressLine3!= null? BusinessContact.kontactsAddresses.addressLine3: BusinessContact.kontactsAddresses.addressLine3 : ""} />
                                    </div>
                                </div>

                                <div className="form-format ws-4">
                                    <div className="label-entries">
                                        <input type="text" className="mss" onChange={(e)=>formHandler(e)} name="kontactsAddresses.addressPostCode" placeholder="Enter Zip Code" 
                                        value={BusinessContact.kontactsAddresses?BusinessContact.kontactsAddresses.addressPostCode!= null? BusinessContact.kontactsAddresses.addressPostCode: "" : ""}/>
                                    </div>
                                </div>
                                
                                <div className="ws-5 ">
                                    <div className="form-format ws-3">
                                        <label className="control-label text-left" >Phone</label>
                                    </div>
                                </div>
                                <div className="ws-4 ">
                                    <div className="form-format ws-3">
                                        <label className="control-label text-left">Fax</label>
                                    </div>
                                </div>


                                <div className="form-format ws-6 ">
                                    <div className="label-entries">
                                        <input type="text" name="phone" className="mss" onChange={(e)=>formHandler(e)} placeholder="Enter Main telephone" value={Object.keys(BusinessContact).length?BusinessContact.kontactsAddresses?BusinessContact.kontactsAddresses.telephone:"" : ""}/>
                                        {telephoneBtn.tel1 && <input type="text" name="kontactsAddresses.telephone2" onChange={(e)=>formHandler(e)} className="mss mt" placeholder="Enter telephone 2" 
                                        value={Object.keys(BusinessContact).length?BusinessContact.kontactsAddresses.telephone2 : ""}/>}
                                        
                                        {telephoneBtn.tel3 &&<input type="text" name="kontactsAddresses.telephone4" onChange={(e)=>formHandler(e)} className="mss mt" placeholder="Enter telephone 4" 
                                        value={Object.keys(BusinessContact).length?BusinessContact.kontactsAddresses.telephone4 : ""}/>}
                                    </div>
                                </div>

                                <div className="form-format ws-6">
                                    <div className="label-entries ws-10">
                                        <input type="text" name="kontactsAddresses.faxNumber" placeholder="Enter Fax" value={Object.keys(BusinessContact).length?BusinessContact.kontactsAddresses?BusinessContact.kontactsAddresses.faxNumber: "" : ""}/>
                                        {telephoneBtn.tel2 && <input type="text" name="kontactsAddresses.telephone3 " onChange={(e)=>formHandler(e)} className="mt" placeholder="Enter telephone 3" 
                                        value={Object.keys(BusinessContact).length?BusinessContact.kontactsAddresses.telephone3 : ""}/>}
                                    </div>

                                    <div className="form-format ws-1 ">
                                        <div className="verticalMediumWide hover-box text-center pull-left">
                                            <ul className="hover-block textboxDropdownList" style={{width: "8vw"}}>
                                                <li className="hover-box text-left">
                                                    <input type="checkbox" name = "tel1" checked={telephoneBtn.tel1} onClick={(e)=>telephone_btn(e)}/> Telephone 2
                                                </li>
                                                <li className="hover-box text-left">
                                                    <input type="checkbox" name = "tel2" checked={telephoneBtn.tel2} onClick={(e)=>telephone_btn(e)}/> Telephone 3
                                                </li>
                                                <li className="hover-box text-left">
                                                    <input type="checkbox" name = "tel3" checked={telephoneBtn.tel3} onClick={(e)=>telephone_btn(e)}/> Telephone 4
                                                </li>
                                            </ul>
                                            <button className="btn btn-primary btn-s">
                                                <i className="icon-plus1"></i>
                                            </button>
                                        </div>
                                    </div>

                                </div>


                                <div className="ws-12">
                                    <div className="form-format ws-4">
                                        <label className="control-label text-left">Email</label>
                                    </div>
                                </div>

                                <div className="form-format ws-12">
                                    <div className="label-entries">
                                        <input type="email" name="emailAdd1" id='imprintEmail' className="mss" onChange={(e)=>formHandler(e)} placeholder="Enter Email" value={Object.keys(BusinessContact).length?BusinessContact.emailAdd1 : ""}/>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className='ws-5 pr pt pl'>
                            <fieldset className="scheduler-border" style={{minWidth:"50%"}}>
                            <legend className="scheduler-border mb"><h2><i className="fa fa-sticky-note-o"></i> Existing Contacts</h2></legend>
                            <div className='ws-12'>
                                <div className="form-format ws-12">
                                    <label className="control-label text-left">Contact</label>
                                    <div className="label-entries">
                                        <div className="ws-8">
                                            <input type="text" name="contact" placeholder="Enter Contact" className='ws-12' value={SearchContact} onChange={(e:any)=>{getSearchContact(e.target.value)}}/>
                                        </div>
                                        <div className="ws-1 hide">
                                            <button className="btn btn-success mb"><i className="icon-search"></i>
                                            </button>
                                        </div>
                                        <div className="ws-4">
                                            <button className="btn-success btn-s pull-right"> Add Contact</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-responsive_big mt ws-12 full-width  allowHscroll">
                                    <table className="table mt">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th >Code</th>
                                                <th >Contact</th>
                                                <th >FirstName</th>
                                                <th >LastName</th>
                                                <th >Email</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ExistContact.map( (data) => {  
                                                return (                                         
                                                <tr>
                                                    <td > <input type='radio' name='select' onClick={()=>setBusinessContact(data)}></input></td>
                                                    <td > {data.kontactCode}</td>
                                                    <td > {data.kontactDisplay}</td>
                                                    <td > {data.firstName}</td>
                                                    <td > {data.lastName}</td>
                                                    <td > {data.emailAdd1}</td>
                                                </tr> 
                                            )})}
                                        </tbody>

                                    </table>

                                    <div className="ws-12 ptl " >
                                    {     
                                        (() => {
                                            if(totalRow && totalRow > 10){
                                                return <Pagination onChange={handleNameOnChangeImprint} totalRows={totalRow} />
                                            }                                                
                                        })()
                                    }  
                                    </div>
                                </div>
                                
                            </div>
                            </fieldset>
                        </div>

                    </div> }

                    {(showTab == 2) && <div className='pLarge'>
                    <fieldset className="scheduler-border">
                        <legend className="scheduler-border mb"><h2 style={{color:"#47529d;"}} ><i className="fa fa-sticky-note-o"></i> Tax Form Information PRSO627996</h2></legend>
                        {saveAlertT && <div className="alert alert-success">Tax Information saved successfully. </div>}
                        {valid && <div className="alert alert-danger">
                            There was a problem submitting your request, one or more fields are missing or incorrect.
                        </div>}

                        <div className="form-format ws-6">
                            <label className="control-label text-left">Name*</label>
                        </div>

                        <div className="form-format ws-6">
                            <label className="control-label text-left">Business Name</label>
                        </div>

                        <div className="form-format ws-6">
                            <div className="label-entries mt">
                                <input type="text" id="name" name="name" value={imprintTaxInfo.name} onChange={(e)=>formHandler(e)} placeholder="Enter Name"/>
                            </div>
                        </div>

                        <div className="form-format ws-6 mt">
                            <div className="label-entries mt">
                                <input type="text" name="businessName" value={imprintTaxInfo.businessName} onChange={(e)=>formHandler(e)} placeholder="Enter Business Name"/>
                            </div>
                        </div>

                        <div className="form-format ws-6">
                            <label className="control-label text-left" style={{width:" 40%"}}>FederalTaxClassification*</label>
                        </div>

                        <div className="form-format ws-6 mt">
                            <label className="control-label text-left" >ExemptPayeeCode</label>
                        </div>

                        <div className="form-format ws-6">
                            <div className="label-entries">
                                <select value={imprintTaxInfo.federalTaxClassification} onChange={(e)=>formHandler(e)} name="federalTaxClassification" id="federalTaxClassification" className="mss">
                                    <option value="" >Choose Federal Tax Classification</option>
                                    <option value="0" label="LLC">LLC</option><option value="1" label="Other">Other</option>
                                    <option value="2" label="Single-Member LLC">Single-Member LLC</option>
                                    </select>
                            </div>
                        </div>
                        <div className="form-format ws-6">
                            <div className="label-entries">
                                <input type="text" value={imprintTaxInfo.exemptPayeeCode} onChange={(e)=>formHandler(e)} name="exemptPayeeCode" className="mss mt" placeholder="Enter Exempt Payee Code"/>
                            </div>
                        </div>

                        <div className="form-format ws-4 mt">
                            <label className="control-label text-left " >Country</label>
                        </div>
                        <div className="form-format ws-4 mt">
                            <label className="control-label text-left"> City</label>
                        </div>

                        <div className="form-format ws-3 mt">
                            <label className="control-label text-left">ZipCode</label>
                        </div>
                        <div className="ws-12 mt">

                        <div className="form-format ws-4">
                            <div className="label-entries">
                                <input type="text" value={imprintTaxInfo.country} onChange={(e)=>formHandler(e)} name="country" className="mss" placeholder="Enter Country"/>
                            </div>
                        </div>
                        <div className="form-format ws-4">
                            <div className="label-entries">
                                <input type="text" value={imprintTaxInfo.city} onChange={(e)=>formHandler(e)} name="city" className="mss" placeholder="Enter City"/>
                            </div>
                        </div>

                        <div className="form-format ws-3" style={{width:"25.8%"}}>
                            <div className="label-entries">
                                <input type="text" value={imprintTaxInfo.zipCode} onChange={(e)=>formHandler(e)} name="zipCode" className="mss" placeholder="Enter Zip Code"/>
                            </div>
                        </div>
                        </div>

                        <div className="form-format ws-12 mt">
                            <label className="control-label text-left mtl ws-3"onChange={(e)=>formHandler(e)} >List Account Numbers</label>

                            <div className="label-entries ws-4" style={{width: "37%"}}>
                                <textarea  name="accountNumbers" value={imprintTaxInfo.accountNumbers} className="mt" placeholder="Enter Account Numbers"></textarea>
                            </div>
                        </div>    

                        <div className="ws-12 mt">
                            <div className="form-format">
                                <label className="control-label text-left mt">Tax Identification Type</label>
                                <div className="label-entries mr">
                                    <div className="ws-4">
                                        <select value={imprintTaxInfo.taxType} onChange={(e)=>formHandler(e)} name="taxType" id="taxType" className="mt">
                                            <option value="">Choose Tax Type</option>
                                            <option value="SSN">SSN</option>
                                            <option value="EIN">EIN</option>
                                        </select>
                                    </div>
                                    <div className="ws-4" style={{width: "37%"}}>
                                        <input type="text" value={imprintTaxInfo.taxNum} onChange={(e)=>formHandler(e)} name="taxNum" placeholder="Enter Tax Account Number" className="mt text-right"/>
                                        <div className="alert-danger ps mt hide">Tax Account Number must be 9 figures</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                    </div> }

                    {(showTab == 3) && <div className='pLarge'>
                    <fieldset className="scheduler-border">
                        <legend className="scheduler-border mb"><h2 style={{color:"#47529d;"}} ><i className="fa fa-sticky-note-o"></i> Company Information</h2></legend>
                        {saveAlertC && <div className="alert alert-success">Company Information saved
                            successfully.
                        </div>}
                        {valid && <div className="alert alert-danger">
                            There was a problem submitting your request, one or more fields are missing or incorrect.
                        </div>}
                        <div className="form-format ws-8 ">
                            <label className="control-label text-left">Company Name*</label>
                        </div>
                        <div className="form-format ws-4">
                            <label className="control-label text-left">Code*</label>
                        </div>

                        <div className="form-format ws-8">
                            <div className="label-entries">
                                <input type="text" id="companyName" value={imprintCompanyInfo.companyName} onChange={(e)=>formHandler(e)} name="companyName" className="mss" placeholder="Enter Company Name"/>
                            </div>
                        </div>
                        <div className="form-format ws-4">
                            <div className="label-entries">
                                <input type="text" id="code" value={imprintCompanyInfo.code}  onChange={(e)=>formHandler(e)} name="code" className="mss " placeholder="Enter Code"/>
                            </div>
                        </div>

                        <div className="form-format ws-4 ptl"> <label className="control-label text-left">Country</label> </div>
                        <div className="form-format ws-4 ptl"> <label className="control-label text-left"> City</label> </div>
                        <div className="form-format ws-4 ptl"> <label className="control-label text-left">State</label>  </div>

                        <div className="form-format ws-4">
                            <div className="label-entries">
                                <input type="text" value={imprintCompanyInfo.country}  onChange={(e)=>formHandler(e)} name="country" className="mss" placeholder="Enter Country"/>
                            </div>
                        </div>
                        
                        <div className="form-format ws-4">
                            <div className="label-entries">
                                <input type="text" value={imprintCompanyInfo.city}  onChange={(e)=>formHandler(e)} name="city" className="mss" placeholder="Enter City"/>
                            </div>
                        </div>

                        <div className="form-format ws-4">
                            <div className="label-entries">
                                <input type="text" value={imprintCompanyInfo.state}  onChange={(e)=>formHandler(e)} name="state" className="mss" placeholder="Enter State"/>
                            </div>
                        </div>
                        <div className="ws-12">

                        <div className="form-format ws-4 mtl">
                            <label className="control-label text-left">Relates to</label>
                        </div>
                        <div className="form-format ws-4 mtl">
                            <label className="control-label text-left">Sequence*</label>
                        </div>
                        </div>
                        <div className="ws-12">
                            <div className="form-format ws-4">
                                <div className="label-entries">
                                    <input type="text" value={imprintCompanyInfo.relatesTo}  onChange={(e)=>formHandler(e)} name="relatesTo" className="mss" placeholder="Enter Relates To"/>
                                </div>
                            </div>
                            <div className="form-format ws-4">
                                <div className="label-entries">
                                    <select value={imprintCompanyInfo.sequence}  onChange={(e)=>formHandler(e)} name="sequence" id="sequence" className="mss">
                                        <option value="">Choose Sequence</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>

                                    </select>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                    </div> }

                    {(showTab == 4) && <div className='pLarge'>
                    <fieldset className="scheduler-border">
                        <legend className="scheduler-border mb"><h2 style={{color:"#47529d;"}} ><i className="fa fa-sticky-note-o"></i> Accounting Details</h2></legend>
                    {saveAlertA && <div className="alert alert-success" data-ng-show="saveAlertA">Accounting Information saved successfully. </div>}
                    {valid && <div className="alert alert-danger"> There was a problem submitting your request, one or more fields are missing or incorrect. </div>}
                     <div>
                        <div className="ws-12 mtl">
                            <div className="form-format ws-4">
                                <label className="control-label text-left">SAP Code*</label>
                            </div>
                        </div>

                        <div className="form-format ws-12">
                            <div className="label-entries">
                                <input type="text" id="vendorCode" value={imprintAccounting.SAPCode} onChange={(e)=>formHandler(e)} name="SAPCode" className="mss" placeholder="Enter SAP Code"/>
                            </div>
                        </div>

                        <div className="ws-12 mtl">
                            <div className="form-format ws-12">
                                <label className="control-label text-left" style={{width: "41%"}}>Inter-Company Debtor Acct</label>
                            </div>
                        </div>

                        <div className="form-format ws-12">
                            <div className="label-entries">
                                <input type="text" value={imprintAccounting.interCompanyDebtorAcct} onChange={(e)=>formHandler(e)}
                                name="interCompanyDebtorAcct" className="mss" placeholder="Enter Inter-Company Debtor Acct"/>
                            </div>
                        </div>

                        <div className="ws-12 mtl">
                            <div className="form-format ws-12">
                                <label className="control-label text-left" style={{width: "41%"}}>Inter-Company Creditor Acct</label>
                            </div>
                        </div>

                        <div className="form-format ws-12">
                            <div className="label-entries">
                                <input type="text" value={imprintAccounting.interCompanyCreditorAcct} onChange={(e)=>formHandler(e)}
                                name="interCompanyCreditorAcct" className="mss " placeholder="Enter Inter-Company Creditor Acct"/>
                            </div>
                        </div>
                     </div>
                     
                    </fieldset>
                    </div> }

                    <div className='box-footer'>                        
                        <button className="btn-success pull-right mb mt mrl" onClick={() => {changePage("next")} }>Next</button>
                       {(showTab>1) && <button className="btn-success pull-right mb mt mrl" onClick={() => {changePage("prev")} }>Back</button>}  
                       <button className="btn-danger pull-right mb mt mrl" onClick={() => {setIsOpen(!isOpen)} }>Close</button>                      
                    </div>
                    
                </div>
            </Drawer>

        </>
    )
}