import { CommonUtilities }  from '../../commonUtils';

export default class dashboardService  {

    static statementsListing() {
        var url = 'approval/statementApproval/list/dashboard';
        return CommonUtilities.getData(url);
    }

    static async projectsListing() {
        var url = 'approval/statementApproval/projectlist/dashboard';
        return await CommonUtilities.getData(url);
    }

    static async tagColor () {
        var url = 'common/tagColor/listAll';
        return await CommonUtilities.getData(url);
    }

    static recyclePool() {
        var url = 'korrectPanel/monitoring/resetApplication';
        return CommonUtilities.getData(url);
    }

    static requestThirdParty(url : string, data : any) {
        return CommonUtilities.getDataThirdParty(url, CommonUtilities.buildQueryString(data, false, false));
    }


}