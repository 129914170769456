import React, { Suspense, useEffect, useState } from 'react';
import Header from '../Header/header';
import { CommonUtilities } from '../../commonUtils';
import PeriodService from '../../components/PeriodSelection/PeriodService';
import globalSearchService from '../commonSearch/globalSearchServices';
import dashboardService from './dashboardServices';
const Dashboard = React.lazy(() => import("./dashboard"));
const Statements = React.lazy(() => import("./Statements"));
const ProjectContracts = React.lazy(() => import("../Dashboard/projectContracts"));

export default function IndexDashboard(param: any) {

    const [flag, setFlag] = useState(true);
    const [flagList, setFlagList] = useState(true);
    const [showTab, setShowTab] = useState(0);
    const [pageNumber, setPageNumber] = useState("");
    var userID = CommonUtilities.getAuthAccess().email;
    var [selectedMonth, setSelectedMonth] = React.useState('2010-01-31T00:00:00');

    useEffect(() => {
        periodListing();
        periodData();
        lookUpTypeList();
        systemFormatsList();

 
    }, []);

    const periodData = () => {
        PeriodService.PeriodCount()
            .then((res) => {
                global.periodCount = res;

                for (var $i = 0; $i < res.length && flag == true; $i++) {
                    if (res[$i].periodCurrent == 1) {
                        global.currectPeriod = res[$i].periodDesc
                        setFlag(false);
                        //currectPeriod = global.period[$i].periodTo;
                        setSelectedMonth(global.currectPeriod);
                    }
                }
            });

        dashboardService.tagColor()
            .then((res) => {
                global.tagColor = res;
            });

    }

    const periodListing = () => {
        PeriodService.PeriodListing(userID).then((res) => {
            global.period = res;
            for (var $i = 0; $i < global.period.length; $i++) {
                if (global.period[$i].periodCurrent == 1) {
                    //currectPeriod = global.period[$i].periodTo;
                    setFlagList(false);
                    setSelectedMonth(global.period[$i].periodTo);
                }
            }
        });
    }

    const onChangePage = (e: any) => {
        setPageNumber(e)
        setShowTab(2)
    }

    const lookUpTypeList = () => {
        globalSearchService.lookUpTypeList()
            .then((res) => {
                global.lookUpTypeList = res;
            });
    }

    const systemFormatsList = () => {
        globalSearchService.systemFormatsList()
            .then((res) => {
                global.systemFormatsList = res;
            });

    }

    /*
                    <Suspense fallback={<div>Loading</div>}>
                    <Statements></Statements>
                </Suspense>

                

{
                    ( () =>{
                        
                        switch(pageNumber){
                            case "Contracts" : return <GridLayout typeGrid="Contracts"/>
                            case "Projects": return <ProjectContractsList/>;
                            case "Contacts": return <ContactGrid/>;
                                                
                        }
                    })()
                }
    */

    return (
        <div className="landingPage ws-12">
            <div className='ws-12 bgWhite'>
                <Header Title={"Dashboard"} />
            </div>

            <div className={showTab == 0 ? "ws-12" : "hide"}>
                <div className="ws-4">
                    <Suspense fallback={<div>Loading</div>}>
                        <Statements></Statements>
                    </Suspense>
                </div>

                <div className="ws-4 mtl bg-white">
                    <Suspense fallback={<div>Loading</div>}>
                        <Dashboard onChangePage={onChangePage} />
                    </Suspense>
                </div>

                <div className="ws-4">
                    <Suspense fallback={<div>Loading</div>}>
                        <ProjectContracts />
                    </Suspense>
                </div>
            </div>



        </div>
    )
}